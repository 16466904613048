import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ArticleSearchResult, PopulatedArticleResult } from '../../shared/model/Articles';
import { ResultData } from '../../shared/model/Shared';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private http: HttpClient) {}

  fetchBlog() {
    return this.http
      .get<PopulatedArticleResult[]>(`${environment.API_URL}/articles/blog`);
  }

  fetchArticle(id: string) {
    return this.http
      .get<PopulatedArticleResult>(
        `${environment.API_URL}/articles/article/${id}`
      );
  }

  fetchSuggestions(idArticle: string) {
    return this.http
      .get<PopulatedArticleResult[]>(
        `${environment.API_URL}/articles/suggestions/${idArticle}`,
      );
  }

  fetchArticlesSearch(search: string, page: number = 1, rows: number = 10) {
    console.log(search)
    return this.http
      .get<ResultData<ArticleSearchResult>>(
        `${environment.API_URL}/articles/search/${search}?page=${page}&rows=${rows}`);
  }
}
