<div class="article-container">
  <div class="row abstract-img p-0">
    <div class="col-12">
      <img class="img-fluid" [src]="attachment.image" alt="">
    </div>
  </div>
  <div class="row abstract-text">
    <div class="col-xl-10 col-lg-11 col-sm-12">
      <div class="card">
        <div class="container">
          <div class="category row">
            <div class="col-4 ps-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="40" viewBox="0 0 4 40" fill="none">
                <path d="M2 38L2 2" stroke="#FFC451" stroke-width="4" stroke-linecap="round"/>
              </svg>
              <span class="ps-2">Article</span>
            </div>
            <div class="col-8 text-muted d-flex align-items-center">
              <p *ngIf="!article.idCompany!.commercialName" class="text-muted">
                Publié le {{ date }}
              </p>
            </div>
          </div>
            <div class="row">
              <div class="col-12">
                <a [routerLink]="['/articles', article.template.toLowerCase() ,article._id]" class="link-secondary">
                  <h4 class="article-title">{{ article.title }}</h4>
                </a>
                <p *ngIf="article.idCompany!.commercialName" class="text-muted">
                  Par
                  <a
                    class="link-secondary"
                    [routerLink]="['entreprises', this.article.idCompany._id]"
                  >
                    {{ article.idCompany.commercialName }} </a
                  >, le {{ date }}
                </p>
                <p *ngIf="!article.idCompany!.commercialName" class="text-muted">
                  Publié le {{ date }}
                </p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
