<header id="header" class="fixed-top bg-secondary">
    <div class="container d-flex align-items-center justify-content-lg-between">
      <h1 class="logo me-auto me-lg-0">
        <a routerLink="/">MC&M<span>.</span></a>
      </h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
  
      <section class="breadcrumbs">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center text-end">
            <ol>
              <li><a routerLink="/">Accueil</a></li>
              <li>Simulateur</li>
            </ol>
          </div>
        </div>
      </section>
      
    </div>
  </header>
  