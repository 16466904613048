import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicComponent } from './basic/basic.component';
import { SearchComponent } from './search/search.component';

const routes: Routes = [
  { path: 'articles/basic/:id', component: BasicComponent },
  {
    path: 'recherche',
    component: SearchComponent,
    title: 'Rechercher un article - Mon Courtier et Moi',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule { }
