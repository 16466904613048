export enum ArticleTemplate {
	BASIC = "Basic",
}

export enum ArticleStatus {
	DRAFT = "Brouillon",
	PUBLISHED = "En ligne",
	WAITING_ADMIN = "En cours de validation",
	HIDDEN = "Masqué",
}

//Articles Entity
export interface Article {
	_id: string;
	idCompany: string;
	title: string;
	template: string;
	dateOfCreation: Date;
	dateOfPublishing: Date;
	status: ArticleStatus;
	verified: boolean;
}