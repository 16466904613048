import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanySearch } from '../shared/model/Analytics';
import {
  CompaniesKind,
  Company,
  KEYWORDS_SEPARATOR,
  PaginatedCompaniesSearchResult,
} from '../shared/model/Companies';
import { AnalyticsService } from '../shared/services/analytics.service';
import { SearchService } from './services/search.service';
import { PaginatorComponent } from '../shared/paginator/paginator.component';
import { ToasterService } from '../shared/services/toaster.service';
import { LeadModalsComponent } from './lead-modals/lead-modals.component';
import * as L from 'leaflet';
import { MapUtils } from './utils/MapUtils';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('modals') modals: LeadModalsComponent | undefined;

  @ViewChild(PaginatorComponent, { static: true }) paginator!: PaginatorComponent;
  companiesKind = CompaniesKind;

  filters = {
    keywords: '',
    location: '',
    wide: true,
    seed: this.getRandomInt(),
    kind: '',
  };

  filtersForm: UntypedFormGroup = this.fb.group({
    keywords: new UntypedFormControl(''),
    location: new UntypedFormControl(''),
    kind: new UntypedFormControl(''),
    wide: new UntypedFormControl(true),
  });

  companies!: PaginatedCompaniesSearchResult;

  numberOfPages: number = 0;
  pages: number[] = [];

  selectedCompany!: Company;
  KEYWORDS_SEPARATOR = KEYWORDS_SEPARATOR;
  public utils!: MapUtils;


  constructor(
    private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private analyticsService: AnalyticsService,
    private toastsService: ToasterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.filtersForm = this.fb.group({
      keywords: new UntypedFormControl(''),
      location: new UntypedFormControl(''),
      kind: new UntypedFormControl(''),
      wide: new UntypedFormControl(true),
    });
    this.initQueryParams();
    this.utils = new MapUtils();
  }


  initQueryParams() {
    console.log('init QP');
    this.route.queryParamMap.subscribe((params) => {
      if (params.keys.length === 0) this.updateQueryParams();
    });
  }

  ngAfterViewInit(): void {
    // this.updateFilters()
    this.parseQueryParams();
    this.newSearch();
  }

  parseQueryParams() {
    this.route.queryParamMap.subscribe((params) => {
      this.filtersForm.patchValue({
        keywords: params.get('q'),
        location: params.get('l'),
        kind: params.get('cat'),
        wide: params.get('w'),
        page: params.get('p'),
      });
      this.updateFilters();
    });
  }

  newSearch() {
    // this.filters.seed = ;
    this.paginator.setPage(1);
    this.searchCompanies();
    this.saveSearch();
  }

  getRandomInt(): number {
    const min: number = 1;
    const max: number = 10000000;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  searchCompanies() {
    this.updateFilters();
    const queryParams = this.paginator.getQueryParams();
    this.searchService
      .searchCompanies(this.filters, queryParams?.p, this.paginator.rows)
      .subscribe({
        next: (result: PaginatedCompaniesSearchResult) => {
          this.companies = result;
          window.scroll(0, 0);
          this.utils.initMap(this.companies);
        },
        error: (err: any) => {
          this.toastsService.showError(err.message);
        },
      });
  }

  updateFilters() {
    const queryParams = this.paginator.getQueryParams();
    const formValues = this.filtersForm.value;
    this.filters.keywords = formValues.keywords;
    this.filters.location = formValues.location;
    this.filters.kind = formValues.kind;
    this.filters.wide = formValues.wide;
    this.updateQueryParams();
  }

  updateQueryParams() {
    const queryParams = this.paginator.getQueryParams();
    const values = this.filtersForm.value;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        q: values.keywords,
        l: values.location,
        cat: values.kind,
        w: values.wide,
        p: queryParams?.p,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });
  }

  saveSearch() {
    const data: CompanySearch = {
      date: new Date(),
      keywords: this.filters.keywords,
      location: this.filters.location,
      seed: this.filters.seed.toString(),
      kind: this.filtersForm.value.kind,
      wide: this.filters.wide,
    };

    this.analyticsService.saveCompanySearch(data).subscribe({
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });
  }

  openModals(company: Company) {
    this.modals?.setIdCompany(company._id);
    this.modals?.openRegistrationModal();
  }
}
