import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ValidatorsService } from '../shared/services/validators.service';

@Component({
  selector: 'app-mortgage-simulator',
  templateUrl: './mortgage-simulator.component.html',
  styleUrls: ['./mortgage-simulator.component.scss'],
})
export class MortgageSimulatorComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    public validatorService: ValidatorsService,
    public validators: ValidatorsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.fragment.subscribe(value => {
      document.getElementById(value as string)?.scrollIntoView({ behavior: 'smooth' });
    })

  }

}
