import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'NavBarBreadcrumbs',
  templateUrl: './nav-bar-breadcrumbs.component.html',
  styleUrls: ['./nav-bar-breadcrumbs.component.scss'],
})
export class NavBarBreadcrumbsComponent implements OnInit {
  @Input() pageName: string;

  constructor() {
    this.pageName = '';
  }

  ngOnInit(): void {}
}
