<div *ngIf="this.profile">
  <NavBarBreadcrumbs
    [pageName]="this.profile.company.commercialName"
  ></NavBarBreadcrumbs>

  <section id="company">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-7 col-lg-8">
          <div class="profile-card">
            <div class="company-infos">
              <div class="company-logo">
                <img
                  [src]="this.profile.company.logo"
                  class="img-thumbnail"
                  [alt]="
                    'Logo du profile de ' + this.profile.company.commercialName
                  "
                />
              </div>
              <div class="card-title">
                <h2>{{ this.profile.company.commercialName }}</h2>
                <div
                *ngFor="
                  let kind of this.profile.company.kind.split(
                    KEYWORDS_SEPARATOR
                  )
                "
              >
                <p class="text-muted">{{ kind }}</p>
              </div>
                <p class="text-muted">
                  Matricule ORIAS: {{ this.profile.company.orias }}
                </p>
                <div class="company-badges">
                  <div
                    *ngFor="
                      let word of this.profile.company.keywords.split(
                        KEYWORDS_SEPARATOR
                      )
                    "
                  >
                    <span class="badge bg-light text-dark">{{ word }}</span>
                  </div>
                </div>
              </div>
              <div class="company-action">
                <button
                  class="btn btn-primary"
                  (click)="this.modals.openRegistrationModal()"
                >
                  Contacter
                </button>
              </div>
            </div>
          </div>

          <div
            class="profile-card mt-4"
            *ngIf="this.profile && this.profile.marketing"
          >
            <div class="card-title">
              <h3>A propos de nous</h3>
              <hr />
              <div class="company-derscription">
                <p [innerHTML]="this.profile.marketing.description"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-5 col-lg-4">
          <div
            class="profile-card"
            *ngIf="this.profile.partners && this.profile.partners.length > 0"
          >
            <div class="card-title">
              <h3>Partenaires</h3>
              <hr />
              <div class="company-partners">
                <div *ngFor="let partner of this.profile.partners">
                  <span>{{ partner.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="profile-card"
            [ngClass]="{ 'mt-4': this.profile.partners.length > 0 }"
            *ngIf="this.profile"
          >
            <div class="card-title">
              <h3>Coordonnées</h3>
              <hr />
              <p>{{ this.profile.company.street }}</p>
              <p>
                {{ this.profile.company.zip }} {{ this.profile.company.city }}
              </p>
              <p class="text-uppercase">{{ this.profile.company.county }}</p>
              <div class="company-website" *ngIf="this.profile.marketing">
                <h3 class="mt-4">Site web</h3>
                <button
                  class="btn btn-link link-primary ps-0"
                  (click)="redirect(this.profile.marketing._id)"
                >
                  {{ this.profile.marketing.website }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div
            *ngIf="this.profile.articles && this.profile.articles.length > 0"
          >
            <div class="profile-card mt-4">
              <div class="card-title">
                <h3>Nos articles</h3>
                <hr />
                <swiper [config]="config">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide center-slide"
                      *ngFor="let article of this.profile.articles"
                    >
                      <Abstract [articleResult]="article"></Abstract>
                    </div>
                  </div>
                  <div *ngIf="this.profile.articles.length > 1">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                  </div>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <LeadModals #modals [idCompany]="this.id"></LeadModals>

  <Footer></Footer>
</div>
