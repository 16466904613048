import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CompanyProfile } from '../../shared/model/Companies';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  fetchCompanyProfile(id: string) {
    return this.http
      .get<CompanyProfile>(`${environment.API_URL}/companies/${id}`)
      .pipe();
  }
}
