import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KEYWORDS_SEPARATOR } from '../../shared/model/Companies';
import { ContainerNumbers, Mortgage, SimulatorFormData, SimulatorVerificationData } from '../../shared/model/Simulator';
import { ToasterService } from '../../shared/services/toaster.service';
import { ValidatorsService } from '../../shared/services/validators.service';
import { SimulatorService } from '../services/simulator.service';

@Component({
  selector: 'MortgageForm',
  templateUrl: './mortgage-form.component.html',
  styleUrls: ['./mortgage-form.component.scss']
})
export class MortgageFormComponent implements OnInit {

  /* STEP 1 : Data for simulation */
  SIMULATOR_FORM: string = 'SIMULATOR_FORM';

  simulatorForm: UntypedFormGroup = this.fb.group({
    borrowedCapital: new UntypedFormControl(null, [Validators.required]),
    interestRates: new UntypedFormControl(null, [Validators.required]),
    insuranceRates: new UntypedFormControl(null, [Validators.required]),
    loanPeriod: new UntypedFormControl(null, [Validators.required]),
  });

  mensualityValue = 0;

  /* STEP 2 : Contact details*/
  CONTACT_DETAILS_FORM: string = 'CONTACT_DETAILS_FORM';

  contactForm: UntypedFormGroup = this.fb.group({
    firstName: new UntypedFormControl(null, [Validators.required]),
    lastName: new UntypedFormControl(null, [Validators.required]),
    phoneNumber: new UntypedFormControl(null, [Validators.required]),
    mail: new UntypedFormControl(null, [Validators.required, Validators.email]),
    consent: new UntypedFormControl(null, [Validators.required, this.validators.consentValidator]),
    newsletter: new UntypedFormControl(false)
  });


  mergedForm: UntypedFormGroup = this.fb.group({
    simulator: this.simulatorForm,
    contact: this.contactForm,
  });

  /* STEP 3 : Verifying contact informations */

  VERIFY_EMAIL_FORM: string = "VERIFY_EMAIL";
  VERIFY_PHONE_FORM: string = "VERIFY_PHONE";
  codeFC = new UntypedFormControl(null, [Validators.required]);
  codeVerificationError: boolean = false;
  disabledResendCodeBtn: boolean = true;


  /* STEP 4 : Choosing the professional for sending */
  SELECT_FORM: string = "SELECT_FORM";

  /* STEP 5 : Results */
  RESULT_FORM: string = 'RESULT_FORM';

  svd: SimulatorVerificationData = {
    idSimulator: '64e48d8a7f8b42270c658015',
    idSimulatorVerificationCode: '',
    code: ''
  };

  displayedForm!: string;
  simulatorData!: SimulatorFormData;
  mortgageArray!: Mortgage[];
  totalInterest = 0
  totalInsurance = 0

  //--------------------- Error Message ----------------------

  errorsMessages = {
    required: 'Ce champ est obligatoire',
    email: "L'adresse mail saisie est incorrecte",
    format: "Le numéro n'est pas au bon format (+33XXXXXXXXX)",
  };

  KEYWORDS_SEPARATOR = KEYWORDS_SEPARATOR;

  constructor(
    private fb: UntypedFormBuilder,
    public validatorService: ValidatorsService,
    private simulatorService: SimulatorService,
    private toastsService: ToasterService,
    public validators: ValidatorsService
  ) {}

  
  ngOnInit(): void {
    this.displayedForm = this.SIMULATOR_FORM;
    this.simulatorData = this.mergedForm.value;

    this.OnInitForm();
    // this.moveToResults()

    this.simulatorForm.valueChanges.subscribe({
      next: () => {
        const values = this.simulatorForm.value
        this.mensualityValue = this.simulatorService.computeMensuality(values)
      }
    })
  }

  //--------------------Form Methods--------------------------
  OnInitForm(): void {
    const defaultValuesSimulator = {
      borrowedCapital: 20000,
      interestRates: 5,
      insuranceRates: 0.15,
      loanPeriod: 10,
    };

    const defaultValuesContact = {
      firstName: 'Ruben',
      lastName: 'GUENOUN',
      phoneNumber: '+33761205341',
      mail: 'ruben.guenoun@moncourtieretmoi.com',
    };

    this.simulatorForm.patchValue(defaultValuesSimulator);
    this.contactForm.patchValue(defaultValuesContact);
    this.simulatorData = this.mergedForm.value;
  }

  updateTelValue(event: {number: string}) {
    const values = this.contactForm.value
    this.contactForm.patchValue({
      phoneNumber: event.number,
      firstName: values.firstName,
      lastName:values.lastName,
      mail: values.mail,
      newsletter: values.newsletter,
      consent: values.consent
    })
  }

  // ------------------- UX Functions ------------------------

  isSelectedForm(formName: string): boolean {
    return this.displayedForm === formName;
  }

  moveToForm(formName: string) {
    this.displayedForm = formName;
  }

  moveToContactDetailsForm() {
    if (this.simulatorForm.invalid) return;
    this.moveToForm(this.CONTACT_DETAILS_FORM);
  }

  moveToVerifyEmailForm() {
    if(this.contactForm.invalid) return;
    const data = this.mergedForm.value
    this.simulatorService.saveSimulatorFormToDB(data).subscribe({
      next: (result) => {
        console.log(result)
        this.svd.idSimulator = result.idSimulator;
        this.svd.idSimulatorVerificationCode = result.idSimulatorVerificationCode;
        this.moveToForm(this.VERIFY_EMAIL_FORM)
        setTimeout(() => this.disabledResendCodeBtn = false, 10000)
      },
      error: (error: any) => {
        this.toastsService.showError(error.error.message);
      }
    })
  }

  moveToVerifyPhoneForm() {
    if(this.codeFC.invalid) return;
    this.disabledResendCodeBtn = true
    this.svd.code = this.codeFC.value;
    this.simulatorService.verifySimulatorMail(this.svd).subscribe({
      next: result => {
        this.svd.idSimulatorVerificationCode = result.idSimulatorVerificationCode
        this.moveToForm(this.VERIFY_PHONE_FORM);
        setTimeout(() => this.disabledResendCodeBtn = false, 10000)
      },
      error: (error: any) => {
        this.toastsService.showError(error.error.message);
      }
    })
  }

  moveToSelectCompanyForm() {
    if(this.codeFC.invalid) return;
    this.svd.code = this.codeFC.value;
    this.simulatorService.verifySimulator(this.svd).subscribe({
      next: () => {
        this.moveToForm(this.SELECT_FORM)
      },
      error: (error: any) => {
        this.toastsService.showError(error.error.message);
      }
    })
  }

  saveCompany(event: {idCompany: string}) {
    this.simulatorService.saveCompany(this.svd.idSimulator, event.idCompany).subscribe({
      next: () => this.moveToResults(),
      error: (error: any) => {
        this.toastsService.showError(error.error.message);
      }
    })
  }

  skipCompany() {
    this.simulatorService.fetchResult(this.svd.idSimulator).subscribe({
      next: (data) => {
        this.simulatorData = this.mergedForm.value
        this.mortgageArray = data.mortgageArray;
        this.computeTotalInterest();
        this.computeInsuranceByMonth();
        this.mensualityValue = this.simulatorService.computeMensuality(this.simulatorForm.value)
        this.moveToForm(this.RESULT_FORM);
      },
      error: (error: any) => {
        this.toastsService.showError(error.error.message);
      }
      });
  }

  moveToResults() {
    console.log(this.mergedForm)
    if (this.mergedForm.invalid) return
    this.svd.code = this.codeFC.value;
    //Save newsletter POST
    this.simulatorService.fetchResult(this.svd.idSimulator).subscribe({
      next: (data) => {
        this.simulatorData = this.mergedForm.value
        this.mortgageArray = data.mortgageArray;
        this.computeTotalInterest();
        this.computeInsuranceByMonth()
        this.moveToForm(this.RESULT_FORM);
      },
      error: (error: any) => {
        this.toastsService.showError(error.error.message);
      }
      });

  }



    // --------- Vérification des infos
    resendCodeHandler(device: string) {
      this.simulatorService.resendCode(this.svd.idSimulator, device).subscribe({
        next: () => {
          this.disabledResendCodeBtn = true;
          setTimeout(() => {
            this.disabledResendCodeBtn = false;
          }, 10000);
        },
        error: (err: any) => {
          this.toastsService.showError(err.error.message);
        },
      });
    }
  
  // ---------- Page détail 
  computeTotalInterest() {
    this.totalInterest = 0;
    this.mortgageArray.forEach(row => {
      this.totalInterest += row.interestPaid
    })
  }

  computeInsuranceByMonth() {
    this.totalInsurance = 0
    const totalInsurance = this.simulatorData.simulator.borrowedCapital * this.simulatorData.simulator.insuranceRates / 100;
    this.totalInsurance = totalInsurance // / this.data.simulator.loanPeriod
  }
}
