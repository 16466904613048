<div class="container" data-aos="zoom-in">
  <div class="section-title">
    <h2>Simulateur</h2>
    <p>Simulez votre emprunt gratuitement</p>
  </div>

  <div class="form-card" [hidden]="!isSelectedForm(SIMULATOR_FORM)">
    <div class="form-nav row">
      <div class="nav-item col-md-6 col-lg-3 passed">Informations</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">Cordoonées</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">
        Sélection du Courtier
      </div>
      <div class="nav-item col-md-6 col-lg-3 waiting">Détails</div>
    </div>

    <div class="form-nav-mobile row">
      <div class="nav-item col-md-6 col-lg-3 passed">1</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">2</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">3</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">4</div>
    </div>
    <div class="form-data">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="form-left col-sm-12 col-md-7">
          <div class="form-title">
            <h4 class="text-center fw-semibold">Renseignez vos informations</h4>
          </div>
          <form [formGroup]="simulatorForm">
            <div class="row g-3 mb-3">
              <div class="col-md-6 col-sm-12">
                <label>Capital emprunté (€) </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Capital emprunté (en €)"
                  formControlName="borrowedCapital"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('borrowedCapital')
                    )
                  "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('borrowedCapital')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <label>Durée (années) </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Durée (années)"
                  formControlName="loanPeriod"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('loanPeriod')
                    )
                  "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('loanPeriod')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-md-6 col-sm-12">
                <label>Taux du crédit (%) </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Taux du crédit"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('interestRates')
                    )
                  "
                  formControlName="interestRates"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('interestRates')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <label>Taux de l'assurance (%)</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Taux de l'assurance"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('insuranceRates')
                    )
                  "
                  formControlName="insuranceRates"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.simulatorForm.get('insuranceRates')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
              </div>
              <div
                class="row g-3 mb-2 align-items-center justify-content-center"
              >
                <div class="col-md-6 col-sm-12 text-center">
                  <button
                    class="btn btn-primary"
                    (click)="moveToContactDetailsForm()"
                    [disabled]="simulatorForm.invalid"
                  >
                    Contacter un courtier spécialisé
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="form-right col-sm-12 col-md-5">
          <div class="form-title">
            <h4 class="text-center fw-semibold">
              Votre mensualité est estimé à
            </h4>
          </div>
          <div #mensuality id="mensuality" class="h1 text-primary fw-bold">
            {{ mensualityValue | number : "1.0-2" }} €
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-card" [hidden]="!isSelectedForm(CONTACT_DETAILS_FORM)">
    <div class="form-nav row">
      <div class="nav-item col-md-6 col-lg-3 passed">Informations</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Cordoonées</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">
        Sélection du Courtier
      </div>
      <div class="nav-item col-md-6 col-lg-3 waiting">Détails</div>
    </div>

    <div class="form-nav-mobile row">
      <div class="nav-item col-md-6 col-lg-3 passed">1</div>
      <div class="nav-item col-md-6 col-lg-3 passed">2</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">3</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">4</div>
    </div>
    <div class="form-data">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="form-left col-sm-12 col-md-7">
          <div class="form-title">
            <h4 class="text-center fw-semibold">
              Indiquez comment le courtier pourra vous joindre
            </h4>
          </div>
          <form [formGroup]="contactForm">
            <div class="row g-3 mb-3">
              <div class="col-md-6 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom"
                  formControlName="lastName"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.contactForm.get('lastName')
                    )
                  "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.contactForm.get('lastName')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Prénom"
                  formControlName="firstName"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.contactForm.get('firstName')
                    )
                  "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.contactForm.get('firstName')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-md-6 col-sm-12">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Adresse mail"
                  formControlName="mail"
                  [class.is-invalid]="
                    validatorService.hasRequiredError(
                      this.contactForm.get('mail')
                    ) ||
                    validatorService.hasMailError(this.contactForm.get('mail'))
                  "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasRequiredError(
                      this.contactForm.get('mail')
                    )
                  "
                >
                  {{ errorsMessages["required"] }}
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    validatorService.hasMailError(this.contactForm.get('mail'))
                  "
                >
                  {{ errorsMessages["email"] }}
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <TelInput
                  [required]="true"
                  [isMobile]="true"
                  (onTelInput)="updateTelValue($event)"
                ></TelInput>
              </div>
            </div>
            <div class="row g-3 mb-2 align-items-center justify-content-center">
              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    formControlName="consent"
                    [class.is-invalid]="
                      validatorService.hasRequiredError(
                        this.contactForm.get('consent')
                      )
                    "
                  />
                  <label class="form-check-label" for="newsletter-check">
                    J'accepte les Conditions Générales d'utilisations
                  </label>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      validatorService.hasRequiredError(
                        this.contactForm.get('consent')
                      )
                    "
                  >
                    {{ errorsMessages["required"] }}
                  </div>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="newsletter"
                    [class.is-invalid]="
                      validatorService.hasRequiredError(
                        this.contactForm.get('newsletter')
                      )
                    "
                  />
                  <label class="form-check-label" for="newsletter-check">
                    J'accepte de recevoir des communications commerciales.
                  </label>
                </div>
              </div>
            </div>
            <div class="row g-3 mb-2 align-items-center justify-content-center">
              <div class="col-6 text-center">
                <button
                  class="btn btn-secondary me-1 mb-1"
                  (click)="moveToForm(SIMULATOR_FORM)"
                >
                  Précédent
                </button>
                <button
                  class="btn btn-primary mb-1"
                  (click)="moveToVerifyEmailForm()"
                  [disabled]="contactForm.invalid"
                >
                  Suivant
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="form-right col-sm-12 col-md-5">
          <div class="form-title">
            <div class="text-center text-muted h4">
              Quelques informations utiles
            </div>
          </div>
          <div class="row text-muted fs-5">
            <div class="col-12">
              <i class="bi bi-lock"></i> Vos données sont protégées.
            </div>
          </div>
          <div class="row text-muted fs-5">
            <div class="col-12">
              <i class="bi bi-person-check"></i> Double vérifications des
              informations saisies
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-card" [hidden]="!isSelectedForm(VERIFY_EMAIL_FORM)">
    <div class="form-nav row">
      <div class="nav-item col-md-6 col-lg-3 passed">Informations</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Cordoonées</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">
        Sélection du Courtier
      </div>
      <div class="nav-item col-md-6 col-lg-3 waiting">Détails</div>
    </div>

    <div class="form-nav-mobile row">
      <div class="nav-item col-md-6 col-lg-3 passed">1</div>
      <div class="nav-item col-md-6 col-lg-3 passed">2</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">3</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">4</div>
    </div>
    <div class="form-data">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-8 col-md-6 col-sm-12 mt-4">
          <div class="form-title">
            <h4 class="text-center fw-semibold">Vérification de votre email</h4>
          </div>
          <p>
            Afin de vérifier votre adresse mail, vous allez recevoir un code de
            vérification.<br />
            Saisissez-le pour passer à l'étape suivante.
          </p>

          <form>
            <div class="row g-3 mb-2">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Code de vérification"
                  [class.is-invalid]="
                    validators.hasRequiredError(this.codeFC.get('code'))
                  "
                  [formControl]="codeFC"
                />
                <button
                  class="btn btn-secondary"
                  type="button"
                  (click)="resendCodeHandler('MAIL')"
                  [disabled]="this.disabledResendCodeBtn"
                >
                  Renvoyer le code
                </button>
                <div
                  class="invalid-feedback"
                  *ngIf="validators.hasRequiredError(this.codeFC.get('code'))"
                >
                  {{ errorsMessages["required"] }}
                </div>
                <div *ngIf="codeVerificationError" class="response-message">
                  <p class="text-danger text-bold">
                    Le code saisie est incorrect ou périmé. Saisissez le à
                    nouveau ou demandez en un nouveau.
                  </p>
                </div>
              </div>
            </div>
            <div class="row g-3 mb-2 align-items-center justify-content-center">
              <div class="col-6 text-center">
                <button
                  class="btn btn-primary"
                  (click)="moveToVerifyPhoneForm()"
                >
                  Suivant
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="form-card" [hidden]="!isSelectedForm(VERIFY_PHONE_FORM)">
    <div class="form-nav row">
      <div class="nav-item col-md-6 col-lg-3 passed">Informations</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Cordoonées</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">
        Sélection du Courtier
      </div>
      <div class="nav-item col-md-6 col-lg-3 waiting">Détails</div>
    </div>

    <div class="form-nav-mobile row">
      <div class="nav-item col-md-6 col-lg-3 passed">1</div>
      <div class="nav-item col-md-6 col-lg-3 passed">2</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">3</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">4</div>
    </div>
    <div class="form-data">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-8 col-md-6 col-sm-12 mt-4">
          <div class="form-title">
            <h4 class="text-center fw-semibold">
              Vérification de votre téléphone
            </h4>
          </div>
          <p>
            Afin de vérifier votre téléphone, vous allez recevoir un code de
            vérification.<br />
            Saisissez-le pour passer à l'étape suivante.
          </p>

          <form>
            <div class="row g-3 mb-2">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Code de vérification"
                  [class.is-invalid]="
                    validators.hasRequiredError(this.codeFC.get('code'))
                  "
                  [formControl]="codeFC"
                />
                <button
                  class="btn btn-secondary"
                  type="button"
                  (click)="resendCodeHandler('PHONE')"
                  [disabled]="this.disabledResendCodeBtn"
                >
                  Renvoyer le code
                </button>
                <div
                  class="invalid-feedback"
                  *ngIf="validators.hasRequiredError(this.codeFC.get('code'))"
                >
                  {{ errorsMessages["required"] }}
                </div>
                <div *ngIf="codeVerificationError" class="response-message">
                  <p class="text-danger text-bold">
                    Le code saisie est incorrect ou périmé. Saisissez le à
                    nouveau ou demandez en un nouveau.
                  </p>
                </div>
              </div>
            </div>
            <div class="row g-3 mb-2 align-items-center justify-content-center">
              <div class="col-6 text-center">
                <button
                  class="btn btn-primary"
                  (click)="moveToSelectCompanyForm()"
                >
                  Suivant
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="form-card" [hidden]="!isSelectedForm(SELECT_FORM)">
    <div class="form-nav row">
      <div class="nav-item col-md-6 col-lg-3 passed">Informations</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Cordoonées</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Sélection du Courtier</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">Détails</div>
    </div>

    <div class="form-nav-mobile row">
      <div class="nav-item col-md-6 col-lg-3 passed">1</div>
      <div class="nav-item col-md-6 col-lg-3 passed">2</div>
      <div class="nav-item col-md-6 col-lg-3 passed">3</div>
      <div class="nav-item col-md-6 col-lg-3 waiting">4</div>
    </div>
    <div class="form-data-scroll">
      <SearchCompany
        (onSelectCompany)="saveCompany($event)"
        (onSkipCompany)="skipCompany()"
      ></SearchCompany>
    </div>
  </div>

  <div class="form-card" [hidden]="!isSelectedForm(RESULT_FORM)">
    <div class="form-nav row">
      <div class="nav-item col-md-6 col-lg-3 passed">Informations</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Cordoonées</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Sélection du Courtier</div>
      <div class="nav-item col-md-6 col-lg-3 passed">Détails</div>
    </div>

    <div class="form-nav-mobile row">
      <div class="nav-item col-md-6 col-lg-3 passed">1</div>
      <div class="nav-item col-md-6 col-lg-3 passed">2</div>
      <div class="nav-item col-md-6 col-lg-3 passed">3</div>
      <div class="nav-item col-md-6 col-lg-3 passed">4</div>
    </div>
    <div class="form-data">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-10 col-md-12 col-sm-12 mt-4">
          <div class="form-title">
            <h4 class="fw-semibold">Les chiffres</h4>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Capital emprunté</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{
                    simulatorData.simulator.borrowedCapital | number : "1.0-2"
                  }}
                  €
                </span>
              </div>
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Durée du prêt</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{ simulatorData.simulator.loanPeriod }}
                  {{ simulatorData.simulator.loanPeriod > 1 ? "ans" : "an" }}
                </span>
              </div>
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Taux de crédit</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{ simulatorData.simulator.interestRates | number : "1.0-2" }}
                  %
                </span>
              </div>
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Taux de l'assurance</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{
                    simulatorData.simulator.insuranceRates | number : "1.0-2"
                  }}
                  %
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Mensualité</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{ mensualityValue | number : "1.0-2" }} €/mois
                </span>
              </div>
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Total intérêts</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{ totalInterest | number : "1.0-2" }} €
                </span>
              </div>
              <div class="row align-items-center g-3">
                <span class="col-auto number-title">Assurance</span>
                <span class="col-auto number-num text-primary fw-bold">
                  {{ totalInsurance | number : "1.0-2" }} €/an
                </span>
              </div>
            </div>
          </div>

          <div class="form-title">
            <h4 class="fw-semibold">Tableau d'amortissement</h4>
          </div>

          <div class="leasing-table table-responsive">
            <table class="table mortgage">
              <thead>
                <tr class="table-primary">
                  <th>Année</th>
                  <th>Capital restant dû DP</th>
                  <th>Intérêt payés</th>
                  <th>Capital amorti</th>
                  <th>Annuité</th>
                  <th>Assurance payée</th>
                  <th>Capital restant dû FP</th>
                </tr>
              </thead>
              <tbody *ngFor="let mortgage of mortgageArray">
                <tr>
                  <td>{{ mortgage.year }}</td>
                  <td>{{ mortgage.remainingCapital | number : "1.0-2" }} €</td>
                  <td>{{ mortgage.interestPaid | number : "1.0-2" }} €</td>
                  <td>{{ mortgage.amortizedCapital | number : "1.0-2" }} €</td>
                  <td>{{ mortgage.annuity | number : "1.0-2" }} €</td>
                  <td>{{ mortgage.insurancePaid | number : "1.0-2" }} €</td>
                  <td>
                    {{ mortgage.remainingCapitalDue | number : "1.0-2" }} €
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-title">
            <h4 class="fw-semibold">Les graphiques d'analyse</h4>
          </div>

          <div class="container graph table-responsive">
            <MortgageGraph
              [mortgageArray]="mortgageArray"
              [simulatorData]="simulatorData"
            >
            </MortgageGraph>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
