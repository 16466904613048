import { LeadFormData, LeadVerificationData } from '../../shared/model/Lead';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  constructor(private http: HttpClient) {}

  saveLead(lead: LeadFormData) {
    return this.http
      .post<{ idLead: string; idLeadVerificationCode: string }>(
        `${environment.API_URL}/leads/save`,
        lead
      )
      .pipe();
  }

  verifyLeadMail(data: LeadVerificationData) {
    return this.http.post<{ idLeadVerificationCode: string }>(
      `${environment.API_URL}/leads/verify-email`,
      data
    );
  }

  verifyLead(data: LeadVerificationData) {
    return this.http.post(`${environment.API_URL}/leads/verify`, data).pipe();
  }

  resendCode(idLead: string) {
    return this.http
      .post(`${environment.API_URL}/leads/resend-code`, { idLead })
      .pipe();
  }
}
