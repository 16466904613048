<form [formGroup]="contactForm" (ngSubmit)="sendContact()">
  <div class="row">
    <div class="col-md-6 form-group">
      <input
        type="text"
        class="form-control"
        placeholder="Nom"
        formControlName="lastName"
        name="lastName"
        id="lastName"
        required
      />
    </div>
    <div class="col-md-6 form-group mt-3 mt-md-0">
      <input
        type="text"
        class="form-control"
        placeholder="Prénom"
        formControlName="firstName"
        name="firstName"
        id="firstName"
        required
      />
    </div>
  </div>
  <div class="form-group mt-3">
    <input
      type="email"
      class="form-control"
      placeholder="Adresse mail"
      formControlName="email"
      name="email"
      id="email"
      required
    />
  </div>
  <div class="form-group mt-3">
    <input
      type="text"
      class="form-control"
      placeholder="Objet"
      formControlName="subject"
      name="subject"
      id="subject"
      required
    />
  </div>
  <div class="form-group mt-3">
    <textarea
      class="form-control"
      placeholder="Message"
      formControlName="message"
      name="message"
      rows="5"
      required
    ></textarea>
  </div>
  <div class="text-center">
    <button
      class="btn btn-primary mt-3"
      type="submit"
      [disabled]="contactForm.invalid"
    >
      Envoyer le message
    </button>
  </div>
</form>
