<!-- Search filters -->
<div class="search-card">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-10 col-md-6 col-sm-12">
        <form [formGroup]="filtersForm">
          <div class="filters-list">
            <div class="filters-item">
              <div class="filter-data">
                <div class="filter-text">
                  <div class="filter-icon">
                    <i class="bi bi-card-text"></i>
                  </div>
                  <h5>Mots clés</h5>
                </div>
                <div class="filter-input">
                  <input
                    class="form-control"
                    type="text"
                    name="search"
                    formControlName="keywords"
                    placeholder="Assurance Vie"
                  />
                </div>
              </div>
            </div>

            <div class="filters-item">
              <div class="filter-data">
                <div class="filter-text">
                  <div class="filter-icon">
                    <i class="bi bi-geo-alt"></i>
                  </div>
                  <h5>Localisation</h5>
                </div>
                <div class="filter-input">
                  <input
                    class="form-control"
                    type="text"
                    name="location"
                    formControlName="location"
                    placeholder="Ville, Département, Région"
                  />
                </div>
              </div>
            </div>

            <div class="filters-item">
              <div class="filter-data">
                <div class="filter-text">
                  <div class="filter-icon">
                    <i class="bi bi-building"></i>
                  </div>
                  <h5>Métier</h5>
                </div>
                <div class="filter-input">
                  <select
                    class="form-select"
                    name="kind"
                    formControlName="kind"
                  >
                    <option value="">Tous</option>
                    <option [value]="this.companiesKind.COA">
                      {{ this.companiesKind.COA }}
                    </option>
                    <option [value]="this.companiesKind.AGA">
                      {{ this.companiesKind.AGA }}
                    </option>
                    <option [value]="this.companiesKind.MA">
                      {{ this.companiesKind.MA }}
                    </option>
                    <option [value]="this.companiesKind.MIA">
                      {{ this.companiesKind.MIA }}
                    </option>
                    <option [value]="this.companiesKind.IAE">
                      {{ this.companiesKind.IAE }}
                    </option>
                    <option [value]="this.companiesKind.COBSP">
                      {{ this.companiesKind.COBSP }}
                    </option>
                    <option [value]="this.companiesKind.MOBSP">
                      {{ this.companiesKind.MOBSP }}
                    </option>
                    <option [value]="this.companiesKind.MOBSPL">
                      {{ this.companiesKind.MOBSPL }}
                    </option>
                    <option [value]="this.companiesKind.MIOBSP">
                      {{ this.companiesKind.MIOBSP }}
                    </option>
                    <option [value]="this.companiesKind.ICE">
                      {{ this.companiesKind.ICE }}
                    </option>
                    <option [value]="this.companiesKind.CIF">
                      {{ this.companiesKind.CIF }}
                    </option>
                    <option [value]="this.companiesKind.ALPSI">
                      {{ this.companiesKind.ALPSI }}
                    </option>
                    <option [value]="this.companiesKind.CIP">
                      {{ this.companiesKind.CIP }}
                    </option>
                    <option [value]="this.companiesKind.IFP">
                      {{ this.companiesKind.IFP }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="search-btn">
          <button class="btn btn-primary" (click)="searchCompanies()">Rechercher</button>
          <button class="btn btn-secondary" (click)="skip()">Passer cette étape</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Search results -->
<div *ngIf="companies">
  <div *ngIf="companies.totalCount === 0">
    <div class="search-card mt-4">
      <div class="row">
        <div class="col-12">Votre recherche n'a retourner aucun résultat.</div>
      </div>
    </div>
  </div>
  <div class="search-results">
    <div *ngIf="companies.premium.length > 0">
      <div *ngFor="let company of companies.premium">
        <div class="company-card">
          <div class="row">
            <div class="col-lg-3 image">
              <img
                [src]="company.logo"
                class="img-thumbnail"
                [alt]="'Logo du profil de ' + company.commercialName"
              />
            </div>
            <div class="col-lg-9 col-md-12 company-infos">
              <div class="company-data">
                <h4 class="company-title">{{ company.commercialName }}</h4>
                <div class="company-location">
                  {{ company.city.toLowerCase() }}, {{ company.county }}
                </div>
                <div
                  class="company-kind"
                  *ngFor="
                    let kind of this.company.kind.split(KEYWORDS_SEPARATOR)
                  "
                >
                  <p class="text-muted mb-0">{{ kind }}</p>
                </div>

                <div class="company-badges">
                  <div
                    *ngFor="
                      let word of company.keywords.split(KEYWORDS_SEPARATOR)
                    "
                  >
                    <span class="badge bg-light text-dark">{{ word }}</span>
                  </div>
                </div>
              </div>
              <div class="company-btn">
                <!-- <a
                  class="btn btn-secondary"
                  [routerLink]="['entreprises', company._id]"
                  >Voir le profil</a
                > -->
                <button class="btn btn-primary" (click)="saveCompany(company._id)">Transmettre</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-results-mobile">
    <swiper [config]="config">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide center-slide"
          *ngFor="let company of companies.premium"
        >
        <div class="company-card">
          <div class="row">
            <div class="col-lg-3 image">
              <img
                [src]="company.logo"
                class="img-thumbnail"
                [alt]="'Logo du profil de ' + company.commercialName"
              />
            </div>
            <div class="col-lg-9 col-md-12 company-infos">
              <div class="company-data">
                <h4 class="company-title">{{ company.commercialName }}</h4>
                <div class="company-location">
                  {{ company.city.toLowerCase() }}, {{ company.county }}
                </div>
                <div
                  class="company-kind"
                  *ngFor="
                    let kind of this.company.kind.split(KEYWORDS_SEPARATOR)
                  "
                >
                  <p class="text-muted mb-0">{{ kind }}</p>
                </div>

                <div class="company-badges">
                  <div
                    *ngFor="
                      let word of company.keywords.split(KEYWORDS_SEPARATOR)
                    "
                  >
                    <span class="badge bg-light text-dark">{{ word }}</span>
                  </div>
                </div>
              </div>
              <div class="company-btn">
                <!-- <a
                  class="btn btn-secondary"
                  [routerLink]="['entreprises', company._id]"
                  >Voir le profil</a
                > -->
                <button class="btn btn-primary" (click)="saveCompany(company._id)">Transmettre</button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div *ngIf="companies.premium.length > 1">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </swiper>
  </div>
</div>

