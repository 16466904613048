import { ArticleResult } from './Articles';
import { Marketing } from './Marketing';
import { MembershipType } from './Membership';

//! Need to be sync with the enum of the API
export enum CompaniesKind {
  // Intermédiaire en assurance
  COA = "COA - Courtier d'assurance ou de réassurance",
  AGA = "AGA - Agent général d'assurance",
  MA = "MA - Mandataire d'assurance",
  MIA = "MIA - Mandataire d'intermediaire d'assurance",
  IAE = 'IAE - Intermediaire en assurance Européen',

  // Intermidaire en opérations de banque et services de paiments
  COBSP = 'COBSP - Courtier en opérations de banque et en services de paiments',
  MOBSP = 'MOBSP - Mandataire non exclusif en opérations de banque et en services de paiments',
  MOBSPL = 'MOBSPL - Mandataire exclusif en opérations de banque et en services de paiments',
  MIOBSP = "MIOBSP - Mandataire d'intermédiare en opérations de banque et en services de paiments",
  ICE = 'ICE - Intermédiaire de crédit immobilier Européen',

  // Conseiller en investissement financier
  CIF = 'CIF - Conseiller en investissement financier',

  // Agent lié de PSI
  ALPSI = 'ALPSI - Agent lié de PSI',

  // Conseiller en investissements participatifs
  CIP = 'CIP - Conseiller en investissements participatifs',

  // Intermédiare en financements participatifs
  IFP = 'IFP - Intermédiaire en financement participatifs',
}

export interface CompaniesFormData {
  company: {
    legalName: string;
    commercialName: string;
    kind: {kind: string}[];
    street: string;
    city: string;
    zip: string;
    county: string;
    state: string;
    orias: string;
    siren: string;
    siret: string;
    mail: string;
    tel: string;
    latitude: number;
    longitude: number;
  };
  partners: {
    keywords: { word: string }[];
    logo: string;
    partners: { name: string }[];
  };
  membership: {
    membership: MembershipType;
  };
  consents: {
    consent: boolean;
    newsletter: boolean;
  };
}

export const KEYWORDS_SEPARATOR: string = '~';

export interface Company {
  _id: string;
  legalName: string;
  commercialName: string;
  street: string;
  zip: string;
  city: string;
  county: string; // Département
  state: string; // Région
  orias: string;
  siren: string;
  siret: string;
  kind: CompaniesKind;
  mail: string;
  tel: string;
  logo: string;
  keywords: string;
  subscription?: string;
  deactivated: boolean;
  dateOfRegistration: Date;
  latitude: number;
  longitude: number;
}

export interface Partners {
  _id: string;
  idCompany: string;
  name: string;
}

export interface CompanyProfile {
  company: Company;
  partners: Partners[];
  marketing: Marketing;
  articles: ArticleResult[];
}

export interface PaginatedCompaniesSearchResult {
	premium: Company[];
	other: Company[];
	totalCount: number;
}