import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { BlogService } from '../../articles/services/blog.service';
import { PopulatedArticleResult } from '../../shared/model/Articles';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  selector: 'Blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss', '../../articles/abstract/abstract.component.scss'],
})
export class BlogComponent implements OnInit {
  blog: PopulatedArticleResult[] = []

  constructor(
    private blogService: BlogService,
    private toastsService: ToasterService
  ) {}

  ngOnInit(): void {
    this.blogService.fetchBlog().subscribe({
      next: (articles: PopulatedArticleResult[]) => {
        this.blog = articles
      },
      error: () => {
        this.toastsService.showError("Impossible de récupérer le blog")
      }
    })
  }

  config: SwiperOptions = {
    breakpoints: {
      576: {
        slidesPerView: 1
      },
      992: {
        slidesPerView: 2,
      }
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  };

  decodeHtml(str: string): string {
    return decodeURI(str)
  }
}
