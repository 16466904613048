import { Component, Input, OnInit } from '@angular/core';
import { PopulatedArticle } from '../model/Articles';

@Component({
  selector: 'NavbarArticles',
  templateUrl: './navbar-articles.component.html',
  styleUrls: [
    './navbar-articles.component.scss',
    '../nav-bar-breadcrumbs/nav-bar-breadcrumbs.component.scss',
  ],
})
export class NavbarArticlesComponent implements OnInit {
  @Input() article!: PopulatedArticle;

  constructor() {}

  ngOnInit(): void {}
}
