<header id="header" class="fixed-top bg-secondary">
  <div class="container d-flex align-items-center justify-content-lg-between">
    <h1 class="logo me-auto me-lg-0">
      <a routerLink="/">MC&M<span>.</span></a>
    </h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

    <nav id="navbar" class="navbar order-last order-lg-0 rounded">
      <ul>
        <li>
          <a
            class="nav-link scrollto"
            [class.active]="isNoAnchor() || isHomeAnchor()"
            href="#home"
            >Accueil</a
          >
        </li>
        <li>
          <a
            class="nav-link scrollto"
            [class.active]="isAboutAnchor()"
            (click)="hideNavMenu()"
            href="#about"
            >A propos</a
          >
        </li>
        <li>
          <a
            class="nav-link scrollto"
            [class.active]="isBlogAnchor()"
            (click)="hideNavMenu()"
            href="#blog"
            >Blog</a
          >
        </li>
        <li>
          <a
            class="nav-link scrollto"
            [class.active]="isContactAnchor()"
            (click)="hideNavMenu()"
            href="#contact"
            >Contact</a
          >
        </li>
        <li>
          <a 
          class="nav-link scrollto"
          routerLink ="/simulateur" 
          >Simulateur</a>
        </li>
      </ul>
      <i
        class="ri-menu-line mobile-nav-toggle"
        (click)="toggleMobileNavbar()"
      ></i>
    </nav>
    <!-- .navbar -->

    <a [routerLink]="['/recherche']" [queryParams]="{q: '', l: '', cat: '', w: true}" class="btn btn-primary">Rechercher </a>
  </div>
</header>
<!-- End Header -->
