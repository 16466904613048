<NavBarBreadcrumbs pageName="Articles"></NavBarBreadcrumbs>

<section>
  <div class="container">
    <div class="search-card">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-9 col-md-6 col-sm-12">
            <form [formGroup]="filterForm">
              <div class="filters-list">
                <div class="filters-item">
                  <div class="filter-icon">
                    <i class="bi bi-newspaper"></i>
                  </div>
                  <div class="filter-text">
                    <div class="filter-input">
                      <input
                        class="form-control"
                        type="text"
                        name="search"
                        id="search"
                        formControlName="search"
                        placeholder="Recherche"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="search-btn">
              <button class="btn btn-primary" (click)="searchArticles()">
                Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="this.articles" >
      <div class="articles-list" *ngFor="let article of articles.data; let i = index">
        <div class="article-container">
          <div class="row">
            <div class="col-12" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-box">
                <div class="col-lg-7 col-md-12 article-infos">
                  <h4 class="article-title">{{ article.article.title }}</h4>
                  <p class="text-muted">
                    Publié par
                    <a
                      class="link-secondary"
                      [routerLink]="[
                        'entreprises',
                        this.article.article.idCompany._id
                      ]"
                    >
                      {{ article.article.idCompany.commercialName }}
                    </a>
                    , le
                    {{ formatDate(article.article.dateOfPublishing.toString()) }}
                  </p>
                  <p
                    class="abstract-text"
                    [innerHtml]="decodeHtml(article.abstract.text)"
                  ></p>
                  <a
                    [routerLink]="['/articles', article.article.template.toLowerCase(),article.article._id]"
                    class="btn btn-primary mt-3"
                    >Lire l'article</a
                  >
                </div>
                <div class="col-lg-5">
                  <div
                    class="image"
                    [style]="
                      'background-image: url(' + article.attachments[0].image + ')'
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Paginator #paginator (refreshData)="loadArticles()" [totalRows]="this.articles && this.articles.total ? this.articles.total : 10"> </Paginator>

  </div>
</section>
<Footer></Footer>
