import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'HomePage',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  search: FormControl = new FormControl('');
  constructor(private router: Router) {}

  ngOnInit(): void {}

  searchWords() {
    this.router.navigate([`/recherche`], {
      queryParams: {
        q: this.search.value,
        l: '',
        cat: '',
        w: true,
      },
    });
  }
}
