import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { format } from 'date-fns';
import { ArticleSearchResult } from '../../shared/model/Articles';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { BlogService } from '../services/blog.service';
import { PaginatorComponent } from '../../shared/paginator/paginator.component';
import { ToasterService } from '../../shared/services/toaster.service';
import { ResultData } from '../../shared/model/Shared';

@Component({
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {

  filters = {
    search: '',
  };

  filterForm: UntypedFormGroup = this.fb.group({
    search: new UntypedFormControl(''),
  });

  articles!: ResultData<ArticleSearchResult>;


  @ViewChild(PaginatorComponent, {static: true}) paginator!: PaginatorComponent

  constructor(
    private fb: UntypedFormBuilder,
    private blogService: BlogService,
    private analyticsService: AnalyticsService,
    private toastsService: ToasterService
  ) {}

  ngOnInit(): void {
    console.log(this.paginator)
    this.loadArticles();
  }

  ngAfterViewInit(): void {
    this.paginator
  }

  searchArticles() {
    this.loadArticles();
    this.analyticsService
      .saveArticleSearch(this.filterForm.value.search)
      .subscribe({
        error: (err: any) => {
          this.toastsService.showError(err.message);
        },
      });
  }

  loadArticles() {
    this.updateFilters();
    const queryParams = this.paginator.getQueryParams()
    this.blogService.fetchArticlesSearch(this.filters.search, queryParams?.p, this.paginator ? this.paginator.rows : 10).subscribe({
      next: (result) => {
        this.articles = result;
      },
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });
  }

  updateFilters() {
    this.filters.search = this.filterForm.get('search')?.value;
  }

  decodeHtml(str: string): string {
    return decodeURI(str);
  }

  formatDate(date: string): string {
    return format(new Date(date), 'dd/MM/yyyy');
  }

}
