import { Component, Input, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { ArticleStatus } from '../../shared/model/Article';
import {
  PopulatedArticle,
  PopulatedArticleResult,
} from '../../shared/model/Articles';
import { Attachment } from '../../shared/model/Attachment';

@Component({
  selector: 'VerticalAbstract',
  templateUrl: './vertical-abstract.component.html',
  styleUrls: ['./vertical-abstract.component.scss'],
})
export class VerticalAbstractComponent implements OnInit {
  @Input() articleResult!: PopulatedArticleResult;

  attachment: Attachment = {
    _id: '',
    idArticle: '',
    image: '',
    altText: '',
    slot: 1
  };
  article: PopulatedArticle = {
    title: '',
    dateOfPublishing: new Date(),
    status: ArticleStatus.PUBLISHED,
    _id: '',
    idCompany: {
      _id: '',
      commercialName: '',
    },
    template: '',
    dateOfCreation: new Date(),
    verified: false
  };
  date: string = '';

  constructor() {}

  ngOnInit(): void {
    this.date = format(new Date(this.article.dateOfPublishing), 'dd/MM/yyyy');
    this.article = this.articleResult.article;
    this.attachment = this.articleResult.attachments[0];
  }

  decodeHtml(str: string): string {
    return decodeURI(str);
  }
}
