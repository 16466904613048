import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor() {}

  homePhoneNumberValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return null;
    if(ctl.value.countryCode !== "FR") return null
    const regex: RegExp = new RegExp(/\+33[1-2-3-4-5][0-9]{8}/);
    const tel: string = ctl.value.e164Number;
    const valid: boolean = tel.match(regex) === null;
    if (valid) return { wrongTelFormat: valid };
    return null;
  }

  mobilePhoneNumberValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return null;
    if(ctl.value.countryCode !== "FR") return null
    const regex: RegExp = new RegExp(/\+33[6-7][0-9]{8}/);
    const tel: string = ctl.value.e164Number;
    const valid: boolean = tel.match(regex) === null;
    if (valid) return { wrongTelFormat: valid };
    return null;
  }

  categoriesValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    const fa: FormArray = ctl as FormArray;
    if (fa.length < 1) return { required: true };
    return null;
  }

  fakeInputValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return null;
    return { fake: false };
  }

  consentValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return { required: true };
    return null;
  }

  // Error getters

  hasRequiredError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('required');
    return false;
  }

  hasMailError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('email');
    return false;
  }

  hasWrongNumberFormatError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('wrongTelFormat');
    return false;
  }
}
