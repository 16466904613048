<ng-template #registration let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Prise de contact</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="leadForm">
      <div class="row g-3 mb-2">
        <div class="col-md-6 col-sm-12">
          <label for="orias">Prénom*</label>
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="
              validators.hasRequiredError(this.leadForm.get('firstName'))
            "
            formControlName="firstName"
          />
          <div
            class="invalid-feedback"
            *ngIf="validators.hasRequiredError(this.leadForm.get('firstName'))"
          >
            {{ errorsMessages["required"] }}
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <label>Nom*</label>
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="
              validators.hasRequiredError(this.leadForm.get('lastName'))
            "
            formControlName="lastName"
          />
          <div
            class="invalid-feedback"
            *ngIf="validators.hasRequiredError(this.leadForm.get('lastName'))"
          >
            {{ errorsMessages["required"] }}
          </div>
        </div>
      </div>

      <div class="row g-3 mb-2 d-none">
        <div class="col-12">
          <label>Age</label>
          <input type="text" />
        </div>
      </div>

      <div class="row g-3 mb-2">
        <div class="col-12">
          <label>Adresse mail*</label>
          <input
            type="email"
            class="form-control"
            [class.is-invalid]="
              validators.hasMailError(this.leadForm.get('mail')) ||
              validators.hasRequiredError(this.leadForm.get('mail'))
            "
            formControlName="mail"
            name="mail"
            id="mail"
          />
          <div
            class="invalid-feedback"
            *ngIf="validators.hasRequiredError(this.leadForm.get('lastName'))"
          >
            {{ errorsMessages["required"] }}
          </div>
          <div
            class="invalid-feedback"
            *ngIf="validators.hasMailError(this.leadForm.get('mail'))"
          >
            {{ errorsMessages["email"] }}
          </div>
        </div>
      </div>

      <div class="row g-3 mb-2">
        <div class="col-12">
          <div class="labels d-flex justify-content-between ">
            <label for="tel">Téléphone*</label>
          </div>
          <TelInput [required]="true" [isMobile]="true" (onTelInput)="updateTelValue($event)"></TelInput>
        </div>
      </div>

      <div class="row g-3 mb-2">
        <div class="col-12">
          <label>Message</label>
          <textarea
            class="form-control"
            formControlName="message"
            rows="5"
            placeholder="Expliquez nous votre besoin"
          ></textarea>
        </div>
      </div>
      <div class="row g-3 mb-2">
        <div class="col-12">*Obligatoire</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="hideRegistrationModal()"
    >
      Annuler
    </button>
    <button
      type="button"
      class="btn btn-lg btn-primary"
      [disabled]="leadForm.invalid"
      (click)="openConsentModal()"
    >
      Valider
    </button>
  </div>
</ng-template>

<ng-template #consent let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">
      Consentement d'utilisation de vos données
    </h4>
  </div>
  <div class="modal-body">
    <div class="row g-3 mb-2">
      <div class="col-12">
        <p>
          En cliquant sur "Continuer", vous acceptez que vos données soit
          utilisées à des fins de vérification et de contact de la part des
          entreprises inscrites sur la plateforme. <br />
          La plateforme MonCourtier&Moi ne revendra pas vos données à des
          fournisseurs tiers.
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="openRegistrationModal()"
    >
      Précédent
    </button>
    <button
      type="button"
      class="btn btn-lg btn-primary"
      (click)="openVerificationMailModal()"
    >
      Continuer
    </button>
  </div>
</ng-template>

<ng-template #waiting let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{ waitingModalTitle }}</h4>
  </div>
  <div class="modal-body">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
</ng-template>

<ng-template #verificationMail let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Vérification de votre adresse mail</h4>
  </div>
  <div class="modal-body">
    <div class="row g-3 mb-2">
      <div class="col-12">
        <p>
          Afin de vérifier votre adresse mail, vous allez recevoir un code de
          vérification.<br />
          Saisissez-le pour passer à l'étape suivante.
        </p>
      </div>
    </div>

    <form>
      <div class="row g-3 mb-2">
        <div class="col-12">
          <label for="orias">Code de vérification</label>
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="validators.hasRequiredError(this.codeFC.get('code'))"
            [formControl]="codeFC"
          />
          <div
            class="invalid-feedback"
            *ngIf="validators.hasRequiredError(this.codeFC.get('code'))"
          >
            {{ errorsMessages["required"] }}
          </div>
          <div *ngIf="codeVerificationError" class="response-message">
            <p class="text-danger text-bold">
              Le code saisie est incorrect ou périmé. Saisissez le à nouveau ou
              demandez en un nouveau.
            </p>
          </div>
        </div>
      </div>
      <div class="row g-3 mb-2">
        <div class="col-12 text-center">
          <button
            class="btn btn-info btn-sm"
            (click)="resendCodeHandler()"
            [disabled]="this.disabledResendCodeBtn"
          >
            Renvoyer le code
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="openRegistrationModal()"
    >
      Précédent
    </button>
    <button
      type="button"
      class="btn btn-lg btn-primary"
      (click)="openVerifyPhoneModal()"
    >
      Valider
    </button>
  </div>
</ng-template>

<ng-template #verificationPhone let-modal>
	<div class="modal-header">
	  <h4 class="modal-title text-center">Vérification de votre numéro de téléphone</h4>
	</div>
	<div class="modal-body">
	  <div class="row g-3 mb-2">
		<div class="col-12">
		  <p>
			Afin de vérifier votre numéro de téléphone, vous allez recevoir un code de
			vérification.<br />
			Saisissez-le pour passer à l'étape suivante.
		  </p>
		</div>
	  </div>
  
	  <form>
		<div class="row g-3 mb-2">
		  <div class="col-12">
			<label for="orias">Code de vérification</label>
			<input
			  type="text"
			  class="form-control"
			  [class.is-invalid]="validators.hasRequiredError(this.codeFC.get('code'))"
			  [formControl]="codeFC"
			/>
			<div
			  class="invalid-feedback"
			  *ngIf="validators.hasRequiredError(this.codeFC.get('code'))"
			>
			  {{ errorsMessages["required"] }}
			</div>
			<div *ngIf="codeVerificationError" class="response-message">
			  <p class="text-danger text-bold">
				Le code saisie est incorrect ou périmé. Saisissez le à nouveau ou
				demandez en un nouveau.
			  </p>
			</div>
		  </div>
		</div>
		<div class="row g-3 mb-2">
		  <div class="col-12 text-center">
			<button
			  class="btn btn-info btn-sm"
			  (click)="resendCodeHandler()"
			  [disabled]="this.disabledResendCodeBtn"
			>
			  Renvoyer le code
			</button>
		  </div>
		</div>
	  </form>
	</div>
	<div class="modal-footer">
	  <button
		type="button"
		class="btn btn-lg btn-secondary"
		(click)="openRegistrationModal()"
	  >
		Précédent
	  </button>
	  <button
		type="button"
		class="btn btn-lg btn-primary"
		(click)="openSuccessModal()"
	  >
		Valider
	  </button>
	</div>
</ng-template>

<ng-template #success let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Vérification de vos informations</h4>
  </div>
  <div class="modal-body">
    <div class="row g-3 mb-2">
      <div class="col-12">
        <p>
          Vos informations ont été transmises à l'entreprise. Vous serez
          contacté prochainement.<br />
          Merci de votre confiance ! <br />
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="modal.close()"
    >
      Fermer
    </button>
  </div>
</ng-template>