// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'http://91.160.180.220:16384',
  API_SIREN_TOKEN: '2ffc6ce3-f284-384b-a2f8-28fc2337ba01',
  STRIPE_PUBLIC_KEY: 'pk_test_51L064LFVozRHe3EM5zTM4KFIE9biw9lUa1R4uSzqG30p87SORbsLukPinCsh8PqFFJr7y2ZMQCvN3DOsjqMA1FhR00sOdkpseh',
  BO_CLIENT_URL:"http://dev.monespace.cgplib.com"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
