import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CompaniesKind, KEYWORDS_SEPARATOR, PaginatedCompaniesSearchResult } from '../../shared/model/Companies';
import { SimulatorService } from '../services/simulator.service';
import { ToasterService } from './../../shared/services/toaster.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'SearchCompany',
  templateUrl: './search-company.component.html',
  styleUrls: ['./search-company.component.scss'],
})
export class SearchCompanyComponent implements OnInit {
  @Output() onSelectCompany = new EventEmitter<{idCompany: string}>();
  @Output() onSkipCompany = new EventEmitter<{idCompany: string}>();
  
  KEYWORDS_SEPARATOR = KEYWORDS_SEPARATOR;
  companiesKind = CompaniesKind;
  filters = {
    keywords: '',
    location: '',
    wide: true,
    seed: this.getRandomInt(),
    kind: '',
  };

  filtersForm: UntypedFormGroup = this.fb.group({
    keywords: new UntypedFormControl(''),
    location: new UntypedFormControl(''),
    kind: new UntypedFormControl(''),
  });

  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  };

  companies!: PaginatedCompaniesSearchResult;
  constructor(
    private fb: UntypedFormBuilder,
    private toasterService: ToasterService,
    private service: SimulatorService

  ) {}

  ngOnInit(): void {
    this.searchCompanies()
  }

  getRandomInt(): number {
    const min: number = 1;
    const max: number = 10000000;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  searchCompanies() {
    this.updateFilters();
    this.service.searchCompany(this.filters).subscribe({
      next: (result) => this.companies = result,
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    });
  }

  updateFilters() {
    const formValues = this.filtersForm.value;
    this.filters.keywords = formValues.keywords;
    this.filters.location = formValues.location;
    this.filters.kind = formValues.kind;
  }

  saveCompany(idCompany: string) {
    this.onSelectCompany.emit({idCompany});
  }

  skip() {
    this.onSkipCompany.emit()
  }
}
