import { Component, Input, OnInit } from '@angular/core';
import {
  PopulatedArticle,
  PopulatedArticleResult,
} from '../../shared/model/Articles';
import { format } from 'date-fns';
import { ArticleResult } from '../../shared/model/Articles';
import { ArticleStatus } from '../../shared/model/Article';
import { Attachment } from '../../shared/model/Attachment';

@Component({
  selector: 'Abstract',
  templateUrl: './abstract.component.html',
  styleUrls: ['./abstract.component.scss'],
})
export class AbstractComponent implements OnInit {
  @Input() articleResult!: PopulatedArticleResult | ArticleResult;

  attachment: Attachment = {
    _id: '',
    idArticle: '',
    image: '',
    altText: '',
    slot: 1,
  };
  article: PopulatedArticle = {
    title: '',
    dateOfPublishing: new Date(),
    status: ArticleStatus.PUBLISHED,
    _id: '',
    template: '',
    dateOfCreation: new Date(),
    verified: false,
    idCompany: {
      _id: '',
      commercialName: '',
    },
  };
  date: string = '';

  constructor() {}

  ngOnInit(): void {
    this.date = format(new Date(this.article.dateOfPublishing), 'dd/MM/yyyy');
    this.article = this.articleResult.article as PopulatedArticle;
    this.attachment = this.articleResult.attachments[0];
  }

  decodeHtml(str: string): string {
    return decodeURI(str);
  }
}
