<NavBarHome></NavBarHome>

<main>
  <section
    id="home"
    class="d-flex align-items-center justify-content-center mt-5"
  >
    <div class="container d-flex align-items-center justify-content-center">
      <div class="row">
        <div
          id="text-col"
          class="col-sm-12 col-md-5"
        >
          <h1>Mon Courtier & Moi</h1>
          <h2>
            Besoin d’un courtier en banque, en finance ou en assurance ? Ne
            cherchez plus, on le fait pour vous !
          </h2>
          <div id="search-link" class="mt-2 col-sm-12">
            <a
              [routerLink]="['/recherche']"
              [queryParams]="{ q: '', l: '', cat: '', w: true }"
              class="btn btn-primary"
              >Je recherche mon courtier
              <i class="bi bi-chevron-double-right"></i
            ></a>

            <a href="#about" class="scrollto btn btn-light ms-lg-2"
              >En savoir +</a
            >
          </div>
        </div>
        <div class="col-sm-12 col-md-7 img-col">
          <img
            id="home-img"
            class="img-fluid"
            src="/assets/img/help.png"
            alt="Help picture"
          />
        </div>
      </div>
    </div>
  </section>

  <section id="cta-fr">
    <div class="container">
      <div class="card shadow border-white p-2">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="section-title">
                <h2>Souveraineté</h2>
                <p>Un service 100% français</p>
              </div>
              <div class="fs-5">
                <p>
                  La plateforme est <strong>souveraine</strong> et hébergée en
                  <strong>France</strong> pour garantir la
                  <strong>protection de vos données</strong> et la
                  <strong>confidentialité</strong> de vos échanges avec les
                  professionnels.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="d-flex align-items-center justify-content-center">
                <img
                  id="home-img"
                  class="img-fluid"
                  src="/assets/img/france-flag.gif"
                  alt="Drapeau de la France"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about" class="about bg-light">
    <div class="container">
      <div class="section-title">
        <h2>Notre concept</h2>
        <p>Trouver l’interlocuteur financier dont vous avez besoin</p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <img src="assets/img/mockup.jpeg" class="img-fluid" alt="" />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content">
          <h3>
            Notre <span class="text-primary">moteur de recherche</span> vous
            permettra de trouver le bon professionnel proche de chez vous
          </h3>
          <p class="fst-3">
            La recherche se concentre autour de 3 critères essentiels:<br />
          </p>
          <ul>
            <li>
              <i class="bi bi-check2-circle fs-3"></i>
              <h4>La localisation</h4>
              Définissez votre localisation en temps réel ou indiquer votre
              région ou votre ville et trouvez les professionnels autour de
              vous.
            </li>
            <li>
              <i class="bi bi-check2-circle fs-3"></i>
              <h4>Des mots clés</h4>
              Exprimez vos besoins sous formes de mots clés pour cibler la
              rechercher et découvrir les professionnels les plus adaptés à
              votre besoin.
            </li>
            <li>
              <i class="bi bi-check2-circle fs-3"></i>
              <h4>La catégorie professionnelle</h4>
              Affinez votre recherche avec le type de courtier que vous
              recherchez.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="cta-dark bg-light">
    <div class="container">
      <div class="card shadow border-secondary p-3 bg-secondary text-white">
        <div class="section-title">
          <h2 class="text-white">A vous de jouer</h2>
          <p class="text-white">Recherchez simplement votre courtier dès maintenant</p>
        </div>
        <div class="row">
          <div class="cta-text col-sm-12">
            <p class="fs-5">Réaliser vos projets le plus rapidement possible en faisant appel à nos professionnels référencés sur toutes les questions d’investissement (financement, investissement, gestion...)</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <a
              [routerLink]="['/recherche']"
              [queryParams]="{ q: '', l: '', cat: '', w: true }"
              class="btn btn-outline-primary"
              >Démarrer la recherche
              <i class="bi bi-chevron-double-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="blog" class="bg-light">
    <Blog></Blog>
  </section>

  <section id="cta-simu" class="bg-light">
    <div class="container">
      <div class="card shadow border-white p-2">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title">
                <h2>Opportunité</h2>
                <p>Faites une simulation pour améliorer le conseil</p>
              </div>
              <div class="cta-text fs-5">
                <p>
                  <strong class="text-primary">Simuler</strong> votre prochain <strong class="text-primary">crédit</strong>
                  et <strong class="text-primary">contacter un courtier</strong> spécialisé pour vous accompagner
                  dans la réalisation.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <a
                [routerLink]="['/simulateur']"
                class="btn btn-primary"
                >Démarrer maintenant
                <i class="bi bi-chevron-double-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<Footer></Footer>
