import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MortgageSimulatorRoutingModule } from './mortgage-simulator-routing.module';
import { MortgageSimulatorComponent } from './mortgage-simulator.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SharedModule } from '../shared/shared.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MortgageGraphComponent } from './mortgage-graph/mortgage-graph.component';
import { MortgageFormComponent } from './mortgage-form/mortgage-form.component';
import { SearchCompanyComponent } from './search-company/search-company.component';
import { SimulatorResultComponent } from './simulator-result/simulator-result.component';

@NgModule({
  declarations: [MortgageSimulatorComponent, MortgageGraphComponent, MortgageFormComponent, SearchCompanyComponent, SimulatorResultComponent],
  imports: [
    CommonModule,
    MortgageSimulatorRoutingModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgApexchartsModule,
    NgxUsefulSwiperModule,
  ],
})
export class MortgageSimulatorModule {}
