import { ValidatorsService } from './../services/validators.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'TelInput',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss']
})
export class TelInputComponent implements OnInit {

  @Input() required: boolean = false;
  @Input() isMobile: boolean = false;

  @Output() onTelInput = new EventEmitter<{number: string}>();

  validators: ValidatorFn[] = []

  tel = new FormControl(null, this.validators)

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  onlyCountries: CountryISO[] = [
    CountryISO.France,
    CountryISO.FrenchGuiana,
    CountryISO.FrenchPolynesia,
    CountryISO.FrenchPolynesia,
    CountryISO.SaintPierreAndMiquelon,
    CountryISO.Guadeloupe,
    CountryISO.Martinique,
    CountryISO.Mayotte,
    CountryISO.SaintBarthélemy,
    CountryISO.SaintMartin,
    CountryISO.WallisAndFutuna,
    CountryISO.Réunion,
  ];


  constructor(
    public validatorsService: ValidatorsService,
  ) {
    if(this.required) this.validators.push(Validators.required)
    this.tel.addValidators(this.validators)
    this.tel.valueChanges.subscribe({
      next: ($event: any) => {
        console.log(this.tel)
        if(this.tel.valid && $event != null) {
          this.onTelInput.emit({number: $event.e164Number})
        }
      }
    })
  }

  ngOnInit(): void {
    if(this.isMobile) this.validators.push(this.validatorsService.mobilePhoneNumberValidator)
    else this.validators.push(this.validatorsService.homePhoneNumberValidator)
  }

  isFR() {
    const value = this.tel.value
    return value  === 'FR'
  }

}
