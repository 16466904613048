import { CompanySearch } from '../model/Analytics';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private http: HttpClient) {}

  saveArticleView(idArticle: string) {
    return this.http
      .post(`${environment.API_URL}/analytics/articles/new-view`, { idArticle })
      .pipe();
  }

  saveArticleSearch(keywords: string) {
    return this.http
      .post(`${environment.API_URL}/analytics/articles/new-search`, {
        keywords,
      })
      .pipe();
  }

  saveCompanyView(idCompany: string) {
    return this.http
      .post(`${environment.API_URL}/analytics/companies/new-view`, { idCompany })
      .pipe();
  }

  saveCompanySearch(data: CompanySearch) {
    return this.http
      .post(`${environment.API_URL}/analytics/companies/new-search`, data)
      .pipe();
  }
}
