import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';

@Component({
  selector: 'NavBarHome',
  templateUrl: './nav-bar-home.component.html',
  styleUrls: ['./nav-bar-home.component.scss'],
})
export class NavBarHomeComponent implements OnInit {
  isMobile = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    AOS.init();
  }

  toggleMobileNavbar(): void {
    if (this.isMobile) this.hideNavMenu();
    else this.showNavMenu();
  }

  hideNavMenu(): void {
    if (this.isMobile) {
      const navbar: Element = document.querySelector('#navbar') as Element;
      const toggle: Element = document.querySelector(
        '.mobile-nav-toggle'
      ) as Element;
      navbar.classList.toggle('navbar-mobile');
      navbar.classList.toggle('navbar');
      toggle.classList.toggle('ri-menu-line');
      toggle.classList.toggle('ri-close-line');
    }
  }

  showNavMenu(): void {
    const navbar: Element = document.querySelector('#navbar') as Element;
    const toggle: Element = document.querySelector(
      '.mobile-nav-toggle'
    ) as Element;
    navbar.classList.toggle('navbar-mobile');
    navbar.classList.toggle('navbar');
    toggle.classList.toggle('ri-menu-line');
    toggle.classList.toggle('ri-close-line');
    this.isMobile = true;
  }

  isNoAnchor(): boolean {
    return this.isAnchor(undefined);
  }

  isHomeAnchor(): boolean {
    return this.isAnchor('home');
  }

  isContactAnchor(): boolean {
    return this.isAnchor('contact');
  }

  isAboutAnchor(): boolean {
    return this.isAnchor('about');
  }

  isBlogAnchor(): boolean {
    return this.isAnchor('blog');
  }

  isMortgageSimulatorAnchor(): boolean {
    return this.isAnchor('mortgageSimulator');
  }

  isAnchor(anchorValue: string | undefined): boolean {
    const anchor: string | undefined = this.getAnchor();
    return anchor === anchorValue;
  }

  getAnchor(): string | undefined {
    const url: string = this.router.url;
    const anchor: string | undefined = url.split('#')[1];
    return anchor;
  }
}
