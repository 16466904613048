<div class="paginator">
  <nav *ngIf="numberOfPages > 1" aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item">
        <button
          class="page-link"
          aria-label="Previous"
          (click)="previousPage()"
          [disabled]="this.queryParams.p == 1"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <li
        class="page-item"
        *ngFor="let page of pages"
        [class.active]="page == this.queryParams.p"
      >
        <button class="page-link" (click)="loadPage(page)">
          {{ page }}
        </button>
      </li>
      <li class="page-item">
        <button
          class="page-link"
          aria-label="Next"
          (click)="nextPage()"
          [disabled]="this.queryParams.p == numberOfPages"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </nav>
</div>
