<NavbarSimulator></NavbarSimulator>

<main>
  <section
    id="home"
    class="section d-flex align-items-center justify-content-center"
  >
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center">
        <div id="text-col" class="col-sm-12 col-md-6">
          <h2>Simulateur de crédit</h2>
          <h1>
            Accéder au simulateur le plus fiable du marché et transmettez votre
            devis à un courtier spécialisé
          </h1>
          <a
            routerLink="/simulateur"
            fragment="simulator"
            class="btn btn-primary mt-5 col-lg-7"
            >Démarrer la simulation</a
          >
        </div>
        <div class="col-sm-12 col-md-6 img-col">
          <img
            id="simulator-img"
            class="img-fluid"
            src="/assets/img/money.jpg"
            alt="Simulator picture"
          />
        </div>
      </div>
    </div>
  </section>

  <section id="services" class="section container section-card mt-lg-4">
    <div class="container" data-aos="zoom-in">
      <div class="section-title">
        <h2>Opportunité</h2>
        <p>Pourquoi faire une simulation ?</p>
        <div class="row mt-5">
          <div class="col-sm-12 col-md-4">
            <div class="why-item">
              <div class="why-icon">
                <div class="icon"><i class="bx bx-tachometer"></i></div>
              </div>
              <div class="why-title">Anticiper</div>
              <p class="why-text text-muted">
                Connaître le montant de vos mesualités vous permet de conserver
                votre confort de vie.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="why-item">
              <div class="why-icon">
                <div class="icon"><i class="bx bx-tachometer"></i></div>
              </div>
              <div class="why-title">Ne pas s'engager</div>
              <p class="why-text text-muted">
                Faire une simulation vous permet d'organiser votre projet sans
                prendre d'engagement.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="why-item">
              <div class="why-icon">
                <div class="icon"><i class="bx bx-tachometer"></i></div>
              </div>
              <div class="why-title">Comparer</div>
              <p class="why-text text-muted">
                Utiliser un simulateur vous permet de comparer différentes
                offres.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="simulator" class="simulator">
    <MortgageForm></MortgageForm>
  </section>
</main>
<Footer></Footer>
