import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticlesRoutingModule } from './articles/articles-routing.module';
import { ArticlesModule } from './articles/articles.module';
import { BlogComponent } from './home-page/blog/blog.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MortgageSimulatorRoutingModule } from './mortgage-simulator/mortgage-simulator-routing.module';
import { MortgageSimulatorModule } from './mortgage-simulator/mortgage-simulator.module';
import { SearchRoutingModule } from './search/search-routing.module';
import { SearchModule } from './search/search.module';
import { ValidatorsService } from './shared/services/validators.service';
import { SharedModule } from './shared/shared.module';
import { TermsConditionsPageComponent } from './terms-conditions-page/terms-conditions-page.component';
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    BlogComponent,
    TermsConditionsPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    NgApexchartsModule,
    NgbModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ArticlesModule,
    ArticlesRoutingModule,
    MortgageSimulatorModule,
    MortgageSimulatorRoutingModule,
    SearchModule,
    SearchRoutingModule,
  ],
  providers: [
    Title,
    ValidatorsService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeFr);
  }
}
