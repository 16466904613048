import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(private http: HttpClient) {}

  saveRedirection(id: string) {
    return this.http
      .get<{ website: string }>(`${environment.API_URL}/redirect/${id}`)
      .pipe();
  }
}
