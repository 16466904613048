import { AbstractComponent } from './abstract/abstract.component';
import { VerticalAbstractComponent } from './vertical-abstract/vertical-abstract.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticlesRoutingModule } from './articles-routing.module';
import { SearchComponent } from './search/search.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicComponent } from './basic/basic.component';


@NgModule({
    declarations: [
        SearchComponent,
        BasicComponent,
        VerticalAbstractComponent,
        AbstractComponent
    ],
    imports: [
        CommonModule,
        ArticlesRoutingModule,
        SharedModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
    ],
    exports: [
        AbstractComponent
    ]
})
export class ArticlesModule { }
