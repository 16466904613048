import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactFormComponent } from './footer/contact-form/contact-form.component';
import { FooterComponent } from './footer/footer.component';
import { NavBarBreadcrumbsComponent } from './nav-bar-breadcrumbs/nav-bar-breadcrumbs.component';
import { NavBarHomeComponent } from './nav-bar-home/nav-bar-home.component';
import { NavbarArticlesComponent } from './navbar-articles/navbar-articles.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ValidatorsService } from './services/validators.service';
import { SharedRoutingModule } from './shared-routing.module';
import { ToasterComponent } from './toaster/toaster.component';
import { NavbarSimulatorComponent } from './nav-bar-simulator/nav-bar-simulator.component';
import { TelInputComponent } from './tel-input/tel-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';



@NgModule({
  declarations: [
    ToasterComponent,
    NavBarHomeComponent,
    NavbarArticlesComponent,
    FooterComponent,
    NavBarBreadcrumbsComponent,
    ContactFormComponent,
    PaginatorComponent,
    NavbarSimulatorComponent,
    TelInputComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIntlTelInputModule,
  ],
  exports: [
    ToasterComponent,
    NavBarHomeComponent,
    NavbarArticlesComponent,
    FooterComponent,
    NavBarBreadcrumbsComponent,
    PaginatorComponent,
    NavbarSimulatorComponent,
    TelInputComponent
  ],
  providers: [ValidatorsService]
})
export class SharedModule { }
