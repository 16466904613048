import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MortgageSimulatorComponent } from './mortgage-simulator.component';
import { SimulatorResultComponent } from './simulator-result/simulator-result.component';

const routes: Routes = [
  { path: '', component: MortgageSimulatorComponent, title: 'Simulation - Mon Courtier et Moi', },
  { path: ':id', component: SimulatorResultComponent, title: 'Détails de ma simulation'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MortgageSimulatorRoutingModule { }
