import { CompanyComponent } from './company/company.component';
import { SharedModule } from './../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ArticlesModule } from '../articles/articles.module';
import { LeadModalsComponent } from './lead-modals/lead-modals.component';

@NgModule({
  declarations: [
    SearchComponent,
    CompanyComponent,
    LeadModalsComponent
  ],
  imports: [
    CommonModule,
    SearchRoutingModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxUsefulSwiperModule,
    ArticlesModule
  ]
})
export class SearchModule { }
