import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadVerificationData } from '../../shared/model/Lead';
import { ToasterService } from '../../shared/services/toaster.service';
import { ValidatorsService } from '../../shared/services/validators.service';
import { LeadsService } from '../services/leads.service';

@Component({
  selector: 'LeadModals',
  templateUrl: './lead-modals.component.html',
  styleUrls: ['./lead-modals.component.scss'],
})
export class LeadModalsComponent {
  @Input() idCompany: string = '';

  @ViewChild('registration') registrationModal: TemplateRef<any> | undefined;

  @ViewChild('consent') consentModal: TemplateRef<any> | undefined;

  @ViewChild('waiting') waitingModal: TemplateRef<any> | undefined;
  waitingModalTitle = '';

  @ViewChild('verificationMail') verificationMailModal:
    | TemplateRef<any>
    | undefined;

  @ViewChild('verificationPhone') verificationPhoneModal:
    | TemplateRef<any>
    | undefined;

  @ViewChild('success') successModal: TemplateRef<any> | undefined;

  leadForm: UntypedFormGroup = this.fb.group({
    firstName: new UntypedFormControl(null, [Validators.required]),
    lastName: new UntypedFormControl(null, [Validators.required]),
    age: new UntypedFormControl(null, [this.validators.fakeInputValidator]),
    mail: new UntypedFormControl(null, [Validators.required, Validators.email]),
    tel: new UntypedFormControl(null, [Validators.required]),
    message: new UntypedFormControl(null, []),
    idCompany: new UntypedFormControl(this.idCompany), //TODO: Ajouter validator required when integrated to a page
  });

  codeFC = new UntypedFormControl(null, [Validators.required]);
  codeVerificationError: boolean = false;
  disabledResendCodeBtn: boolean = false;

  lvd: LeadVerificationData = {
    idLead: '',
    idLeadVerificationCode: '',
    code: '',
  };

  errorsMessages = {
    required: 'Ce champ est obligatoire',
    email: "L'adresse mail saisie est incorrecte",
    wrongTelFormat:
      'Le format du numéro est incorrect. Le format attendu est +33612345678',
  };

  consent = false;
  closeResult = '';

  constructor(
    public modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public validators: ValidatorsService,
    private leadsService: LeadsService,
    private toastsService: ToasterService
  ) {}

  public setIdCompany(idCompany: string) {
    this.idCompany = idCompany;
    this.leadForm.patchValue({
      idCompany: this.idCompany,
    });
  }

  updateTelValue(event: {number: string}) {
    this.leadForm.patchValue({
      tel: event.number
    })
  }

  // ------------------- Modal Functions ----------------------------

  openModal(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, { size: 'lg', centered: true, backdrop: 'static' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Closing registration modal
  hideRegistrationModal() {
    this.modalService.dismissAll();
    this.leadForm.reset();
    this.consent = false;
  }

  //Opening waiting modal
  openWaitingModal(title: string) {
    this.waitingModalTitle = title;
    this.openModal(this.waitingModal);
  }

  // Step 0: Opening registration modal
  public openRegistrationModal() {
    this.leadForm.patchValue({
      idCompany: this.idCompany,
    });
    this.openModal(this.registrationModal);
  }

  // Step 1 : Move to consent modal
  openConsentModal() {
    if (this.consent) this.openVerificationMailModal();
    else this.openModal(this.consentModal);
  }

  // Step 2 : Save data because we got user consent and open verifyMail modal
  openVerificationMailModal() {
    if (this.leadForm.invalid) return;
    this.openWaitingModal('Traitement de vos informations');
    const data = this.leadForm.value
    this.leadsService.saveLead(data).subscribe({
      next: (result: { idLead: string; idLeadVerificationCode: string }) => {
        this.lvd.idLeadVerificationCode = result.idLeadVerificationCode;
        this.lvd.idLead = result.idLead;
        this.openModal(this.verificationMailModal);
        this.codeVerificationError = false;
      },
      error: (err: any) => {
        this.openModal(this.registrationModal);
        this.toastsService.showError(err.error.message);
        this.codeVerificationError = true;
      },
    });
  }

  resendCodeHandler() {
    this.leadsService.resendCode(this.lvd.idLead).subscribe({
      next: () => {
        this.disabledResendCodeBtn = true;
        setTimeout(() => {
          this.disabledResendCodeBtn = false;
        }, 10000);
      },
      error: (err: any) => {
        this.toastsService.showError(err.error.message);
      },
    });
  }

  // Step 3 : Verify Email code then move to verify phone modal
  openVerifyPhoneModal() {
    if (this.codeFC.invalid) return;
    this.openWaitingModal('Vérification de votre adresse mail');
    this.lvd.code = this.codeFC.value;
    this.leadsService.verifyLeadMail(this.lvd).subscribe({
      next: (result: { idLeadVerificationCode: string }) => {
        this.lvd.idLeadVerificationCode = result.idLeadVerificationCode;
        this.codeFC.reset();
        this.openModal(this.verificationPhoneModal);
      },
      error: (err: any) => {
        this.codeVerificationError = true;
        this.openModal(this.verificationMailModal);
        this.toastsService.showError(err.error.message);
      },
    });
  }

  // Step 4 : Verify phone then move to success modal
  openSuccessModal() {
    if (this.codeFC.invalid) return;
    this.openWaitingModal('Vérification de votre numéro de téléphone');
    this.lvd.code = this.codeFC.value;
    this.leadsService.verifyLead(this.lvd).subscribe({
      next: () => {
        this.openModal(this.successModal);
        this.leadForm.reset();
        this.codeFC.reset();
        this.consent = false;
        this.codeVerificationError = false;
      },
      error: (err: any) => {
        this.codeVerificationError = true;
        this.openModal(this.verificationPhoneModal);
        this.toastsService.showError(err.error.error.message);
      },
    });
  }
}
