<div class="article-container">
  <div class="icon-box">
    <img [src]="attachment.image" class="image" [alt]="attachment.altText" />
    <div class="article-infos">
      <h4 class="article-title">{{ article.title }}</h4>
      <p class="text-muted">
        Publié par
        <a
          *ngIf="this.article"
          [routerLink]="['entreprises', this.article.idCompany!._id]"
          class="link-info"
          >{{ this.article.idCompany!.commercialName }}</a
        >
        , le {{ date }}
      </p>
      <p
        class="abstract-text"
        [innerHtml]="decodeHtml(articleResult.abstract.text)"
      ></p>
      <a [routerLink]="['/articles', article._id]" class="btn btn-primary mt-3"
        >Lire l'article</a
      >
    </div>
  </div>
</div>
