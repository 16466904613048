import { ToasterService } from './../../shared/services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { SimulatorService } from '../services/simulator.service';
import { ActivatedRoute } from '@angular/router';
import { Mortgage, SimulatorData, SimulatorFormData } from '../../shared/model/Simulator';

@Component({
  templateUrl: './simulator-result.component.html',
  styleUrls: ['./simulator-result.component.scss']
})
export class SimulatorResultComponent implements OnInit {
  id: string = "";

  data!: SimulatorFormData;
  array !: Mortgage[];

  totalInterest: number = 0;
  insurancePaidYearly: number = 0;
  mensuality: number = 0;
  
  constructor(
    private route: ActivatedRoute,
    private service: SimulatorService,
    private toastService: ToasterService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    
    this.service.fetchData(this.id).subscribe({
      next: result => {
        this.array = result.mortgageArray;
        this.formatData(result.simulator)
        this.computeTotalInterest();
        this.computeInsuranceByMonth()
        this.mensuality = this.service.computeMensuality(this.data)
      }, 
      error: (error: any) => {
        this.toastService.showError(error.error.message)
      }
    })
  }

  formatData(data: SimulatorData) {
    this.data = {
      simulator: {
        borrowedCapital: data.borrowedCapital,
        interestRates: data.interestRates,
        insuranceRates: data.insuranceRates,
        loanPeriod: data.loanPeriod
      },
      contact: {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        mail: data.mail,
        consent: true,
        newsletter: false
      }
    }
  }

  computeTotalInterest() {
    this.totalInterest = 0;
    this.array.forEach(row => {
      this.totalInterest += row.interestPaid
    })
  }

  computeInsuranceByMonth() {
    this.insurancePaidYearly = 0
    const totalInsurance = this.data.simulator.borrowedCapital * this.data.simulator.insuranceRates / 100;
    this.insurancePaidYearly = totalInsurance // / this.data.simulator.loanPeriod
  }
}
