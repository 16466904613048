import { LeadModalsComponent } from './../lead-modals/lead-modals.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SwiperOptions } from 'swiper';
import { CompanyProfile, KEYWORDS_SEPARATOR } from '../../shared/model/Companies';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { CompanyService } from '../services/company.service';
import { RedirectService } from '../services/redirect.service';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  id: string = '';
  profile!: CompanyProfile;

  @ViewChild('modals') modals: LeadModalsComponent | undefined;

  openModal: any;

  KEYWORDS_SEPARATOR = KEYWORDS_SEPARATOR;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private redirectService: RedirectService,
    private analyticsService: AnalyticsService,
    private toastsService: ToasterService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.companyService.fetchCompanyProfile(this.id).subscribe({
      next: (profile: CompanyProfile) => {
        this.profile = profile;
        this.title.setTitle(
          this.profile.company.commercialName + ' - Mon Courtier et Moi'
        );
      },
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });

    this.analyticsService.saveCompanyView(this.id).subscribe({
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });
  }

  config: SwiperOptions = {
    breakpoints: {
      576: {
        slidesPerView: 1
      },
      992: {
        slidesPerView: 2,
      }
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  };

  redirect(idMarketing: string) {
    this.redirectService.saveRedirection(idMarketing).subscribe({
      next: (result: { website: string }) => {
        window.open(result.website, '_blank');
      },
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });
  }
}
