import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'NavbarSimulator',
  templateUrl: './nav-bar-simulator.component.html',
  styleUrls: [
    './nav-bar-simulator.component.scss',
    '../nav-bar-breadcrumbs/nav-bar-breadcrumbs.component.scss',
  ],
})
export class NavbarSimulatorComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
