import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  Mortgage,
  SimulatorData,
  SimulatorFormData,
  SimulatorVerificationData,
} from '../../shared/model/Simulator';
import { PaginatedCompaniesSearchResult } from '../../shared/model/Companies';

@Injectable({
  providedIn: 'root',
})
export class SimulatorService {
  constructor(private http: HttpClient) {}

  saveSimulatorFormToDB(data: any) {
    return this.http.post<{
      idSimulator: string;
      idSimulatorVerificationCode: string;
    }>(`${environment.API_URL}/simulator/save`, { data });
  }

  verifySimulatorMail(data: SimulatorVerificationData) {
    return this.http.post<{ idSimulatorVerificationCode: string }>(
      `${environment.API_URL}/simulator/verify-mail`,
      data
    );
  }

  verifySimulator(data: SimulatorVerificationData) {
    return this.http.post(`${environment.API_URL}/simulator/verify`, data);
  }

  searchCompany(filters: any, page: number = 1, rows: number = 10) {
    return this.http.post<PaginatedCompaniesSearchResult>(
      `${environment.API_URL}/simulator/search?page=${page}&rows=${rows}`,
      filters
    );
  }

  saveCompany(idSimulator: string, idCompany: string) {
    return this.http.post(`${environment.API_URL}/simulator/select-company`, {
      idSimulator,
      idCompany,
    });
  }

  fetchResult(_id: string) {
    return this.http.get<{ mortgageArray: Mortgage[] }>(
      `${environment.API_URL}/simulator/result/${_id}`
    );
  }

  resendCode(idSimulator: string, device: string) {
    return this.http
      .post(`${environment.API_URL}/simulator/resend-code`, { idSimulator, device })
  }

  fetchData(idSimulator: string) {
    return this.http
      .get<{simulator: SimulatorData, mortgageArray: Mortgage[]}>
      (`${environment.API_URL}/simulator/${idSimulator}`)
  }

  computeMensuality(data: any) {
    if(data.interestRates === null || data.loanPeriod === null || data.borrowedCapital === null) return 0
    const pow = Math.pow((1 + (data.interestRates / 100)), (-data.loanPeriod))
    const mensualityValue = (data.borrowedCapital * (data.interestRates/100) ) / ((1 - pow) * 12) 
    return mensualityValue;
  }

  computeTotalInterest(array: Mortgage[]) {
    let totalInterest = 0;
    array.forEach(row => {
      totalInterest += row.interestPaid
    })
    return totalInterest
  }

  computeInsuranceByYear(capital: number, insuranceRates: number) {
    const totalInsurance = capital * insuranceRates / 100;
    return totalInsurance
  }
}
