<NavbarArticles *ngIf="this.article" [article]="this.article.article"></NavbarArticles>

<section class="article">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="article-title">
          <h1 *ngIf="this.article">{{ this.article.article.title }}</h1>
          <p class="text-muted">
            Article publié par
            <a
              *ngIf="this.article"
              [routerLink]="[
                'entreprises',
                this.article.article.idCompany!._id
              ]"
              class="link-info"
              >{{ this.article.article.idCompany!.commercialName }}</a
            >, le {{ date }}.
          </p>
        </div>
        <div class="article-image">
          <img
            *ngIf="this.article"
            [src]="this.article.attachments[0].image"
            [alt]="this.article.attachments[0].altText"
          />
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div
          *ngIf="this.article"
          class="article-content"
          [innerHTML]="this.article.textBlocks[0].textHTML"
        ></div>
      </div>
    </div>
  </div>
</section>


<div *ngIf="suggestions.length > 0">
	<div class="suggestions">
	  <div class="container">
		<div class="section-title">
		  <h2>Suggestions</h2>
		  <p>Voici d'autres articles qui pourraient vous plaires.</p>
		</div>
		<div class="container">
		  <div class="row" *ngFor="let article of suggestions; let i = index">
			<div class="col-sm-12 col-md-6 col-lg-4">
			  <VerticalAbstract [articleResult]="article"></VerticalAbstract>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>

<Footer></Footer>
