import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PaginatedCompaniesSearchResult } from '../../shared/model/Companies';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  searchCompanies(filters: any, page: number = 1, rows: number = 10) {
    return this.http
      .post<PaginatedCompaniesSearchResult>(`${environment.API_URL}/search?page=${page}&rows=${rows}`, filters)
      .pipe();
  }
}
