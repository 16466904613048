import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { TermsConditionsPageComponent } from './terms-conditions-page/terms-conditions-page.component';
import { MortgageSimulatorComponent } from './mortgage-simulator/mortgage-simulator.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    title:
      "La plateforme pour trouver le courtier qu'il vous faut - Mon Courtier et Moi",
  },
  {
    path: 'articles',
    loadChildren: () =>
      import('./articles/articles.module').then((m) => m.ArticlesModule),
  },
  {
    path: 'recherche',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'cgu',
    component: TermsConditionsPageComponent,
    title: 'Conditions générales - Mon Courtier et Moi',
  },
  {
    path: 'simulateur',
    loadChildren: () =>
      import('./mortgage-simulator/mortgage-simulator.module').then(
        (m) => m.MortgageSimulatorModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
