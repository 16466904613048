import { Injectable } from '@angular/core';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class DatesService {
  public DATE_FORMAT: string = 'yyyy-MM-dd';
  public DATE_FORMAT_ALT: string = 'dd/MM/yyyy';

  constructor() {}

  formatDate(date: string | Date): string {
    return format(new Date(date), this.DATE_FORMAT);
  }

  formatDateAlt(date: string | Date): string {
    return format(new Date(date), this.DATE_FORMAT_ALT);
  }
}
