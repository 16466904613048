<NavbarSimulator></NavbarSimulator>

<section class="section mt-5" *ngIf="this.array && this.array">
  <div class="container">
    <div class="form-data">
      <div class="row p-2">
        <div class="col-12 p-2">
          <div class="card info-card p-4">
            <h4 class="card-title fw-semibold">Les chiffres</h4>
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Capital emprunté</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ data.simulator.borrowedCapital | number : "1.0-2" }} €
                  </span>
                </div>
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Durée du prêt</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ data.simulator.loanPeriod }}
                    {{ data.simulator.loanPeriod > 1 ? "ans" : "an" }}
                  </span>
                </div>
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Taux de crédit</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ data.simulator.interestRates | number : "1.0-2" }} %
                  </span>
                </div>
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Taux de l'assurance</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ data.simulator.insuranceRates | number : "1.0-2" }} %
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Mensualité</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ mensuality | number : "1.0-2" }} €/mois
                  </span>
                </div>
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Total intérêts</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ totalInterest | number : "1.0-2" }} €
                  </span>
                </div>
                <div class="row align-items-center g-3">
                  <span class="col-auto number-title">Assurance</span>
                  <span class="col-auto number-num text-primary fw-bold">
                    {{ insurancePaidYearly | number : "1.0-2" }} €/an
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	<div class="row p-2">
		<div class="col-12 p-12">
		  <div class="card info-card p-4 ">
			<h4 class="card-title fw-semibold">Le tableau d'ammortissement</h4>
			<div class="row">
			  <div class="col-12">
				<div class="table-responsive">
				  <table class="table mortgage">
					<thead>
					  <tr class="table-primary">
						<th>Année</th>
						<th>Capital restant dû DP</th>
						<th>Intérêt payés</th>
						<th>Capital amorti</th>
						<th>Annuité</th>
						<th>Assurance payée</th>
						<th>Capital restant dû FP</th>
					  </tr>
					</thead>
					<tbody *ngFor="let mortgage of array">
					  <tr>
						<td>{{ mortgage.year }}</td>
						<td>{{ mortgage.remainingCapital | number : "1.0-2" }} €</td>
						<td>{{ mortgage.interestPaid | number : "1.0-2" }} €</td>
						<td>{{ mortgage.amortizedCapital | number : "1.0-2" }} €</td>
						<td>{{ mortgage.annuity | number : "1.0-2" }} €</td>
						<td>{{ mortgage.insurancePaid | number : "1.0-2" }} €</td>
						<td>{{ mortgage.remainingCapitalDue | number : "1.0-2" }} €</td>
					  </tr>
					</tbody>
				  </table>
				</div>
			  </div>
			</div>
	
			<h4 class="card-title fw-semibold">Graphiques</h4>
			<div class="container graph">
			  <MortgageGraph [mortgageArray]="array" [simulatorData]="data">
			  </MortgageGraph>
			</div>
		  </div>
		</div>
	  </div>

</div>

  
</section>

<Footer></Footer>
