import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Mortgage, SimulatorFormData } from '../../shared/model/Simulator';

declare var ApexCharts: any;

@Component({
  selector: 'MortgageGraph',
  templateUrl: './mortgage-graph.component.html',
  styleUrls: ['./mortgage-graph.component.scss'],
})
export class MortgageGraphComponent implements OnChanges {
  @Input() mortgageArray!: Mortgage[];
  @Input() simulatorData!: SimulatorFormData;
  yearsData: number[] = [];
  step!: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if(!this.mortgageArray) return 
    this.step = this.determineStep();
    this.InitYearArray();
    this.InitStackColumnedChart();
    this.InitDonutChart();
    this.InitComboChart();
  }

  //-------------------------Column Stacked Chart functions---------------------

  InitStackColumnedChart(): void {
    const insurance = this.getInsuranceDataWithStep();
    const annuity = this.getAnnuityDataWithStep();
    const interest = this.getInterestDataWithStep();

    const options = {
      series: [
        { name: 'Capital remboursé', data: annuity },
        { name: 'Intêret', data: interest },
        { name: 'Assurance', data: insurance },
      ],
      chart: {
        type: 'bar',
        height: 400,
        width: 600,
        stacked: true,
      },
      title: {
        text: 'Evolution des dépenses',
        align: 'left',
        offsetX: 150,
      },
      responsive: [
        {
          breakpoint: 576,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: this.yearsData,
        title: {
          text: 'Années',
        },
      },
      yaxis: {
        title: {
          text: 'Montant en euros',
        },
        forceNiceScale: true,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'right',
        offsetX: 0,
        offsetY: 50,
      },
    };

    const chart = new ApexCharts(
      document.querySelector('#stacked-column-chart'),
      options
    );
    chart.render();
  }

  determineStep(): number {
    const maxYears = this.mortgageArray.length;
    if (maxYears < 10) {
      return 1;
    } else if (maxYears >= 10 && maxYears < 20) {
      return 2;
    } else {
      return 5;
    }
  }

  InitYearArray(): void {
    const loanPeriod = this.simulatorData.simulator.loanPeriod;
    const actualDate = new Date().getFullYear();
    const expectedDate = loanPeriod + actualDate - 1;

    for (let i = actualDate; i < expectedDate; i += this.step) {
      this.yearsData.push(i);
    }
  }

  getAnnuityDataWithStep(): number[] {
    const remainingCapitalData: number[] = [];
    for (let i = 0; i < this.mortgageArray.length; i += this.step) {
      const rpd = parseFloat(
        (
          this.simulatorData.simulator.borrowedCapital -
          this.mortgageArray[i].remainingCapitalDue
        ).toFixed(2)
      );
      remainingCapitalData.push(rpd);
    }
    return remainingCapitalData;
  }

  getInsuranceDataWithStep(): number[] {
    const insuranceData: number[] = [];
    for (let i = 0; i < this.mortgageArray.length; i += this.step) {
      //Insurance remaining the same
      const ins = parseFloat(this.mortgageArray[i].insurancePaid.toFixed(2));
      insuranceData.push(ins);
    }
    return insuranceData;
  }

  getInterestDataWithStep(): number[] {
    const interestData: number[] = [];
    for (let i = 0; i < this.mortgageArray.length; i += this.step) {
      const int = parseFloat(this.mortgageArray[i].interestPaid.toFixed(2));
      interestData.push(int);
    }
    return interestData;
  }

  getRemainingCapitalDataWithStep(): number[] {
    const rCapital: number[] = [];
    for (let i = 0; i < this.mortgageArray.length; i += this.step) {
      const ins = parseFloat(
        this.mortgageArray[i].remainingCapitalDue.toFixed(2)
      );
      rCapital.push(ins);
    }
    return rCapital;
  }

  //------------------------------Donut Chart-----------------------------

  InitDonutChart(): void {
    const borrowedCapital = this.simulatorData.simulator.borrowedCapital;
    const totalInsurance = this.getTotalInsurance();
    const totalInterest = this.getTotalInterest();
    var options = {
      series: [borrowedCapital, totalInsurance, totalInterest],
      chart: {
        height: 400,
        width: 600,
        type: 'donut',
        stacked: true,
      },
      title: {
        text: 'Répartition des dépenses',
        align: 'left',
        offsetX: 130,
      },
      labels: ['Capital remboursé', 'Assurance', 'Intérêt'],
      responsive: [
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };

    var chart = new ApexCharts(document.querySelector('#donut-chart'), options);
    chart.render();
  }

  getTotalInterest(): number {
    let interest = 0;
    for (let i = 0; i < this.mortgageArray.length; i++) {
      interest += this.mortgageArray[i].interestPaid;
    }
    return parseFloat(interest.toFixed(2));
  }

  getTotalInsurance(): number {
    let insurance = 0;
    for (let i = 0; i < this.mortgageArray.length; i++) {
      insurance += this.mortgageArray[i].insurancePaid;
    }
    return parseFloat(insurance.toFixed(2));
  }

  //--------------------------Combo Chart-----------------------------

  InitComboChart(): void {
    const annuity = this.getAnnuityDataWithStep();
    const interest = this.getInterestDataWithStep();
    const rCapital = this.getRemainingCapitalDataWithStep();

    var options = {
      series: [
        {
          name: 'Capital Remboursé',
          type: 'column',
          data: annuity,
        },
        {
          name: 'Intêret',
          type: 'column',
          data: interest,
        },
        {
          name: 'Restant dû',
          type: 'line',
          data: rCapital,
        },
      ],
      chart: {
        height: 400,
        width: 800,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
      },
      title: {
        text: "Evolution du capital remboursé, des intérêts et du restant dû en milliers d'euros",
        align: 'left',
        offsetX: 110,
      },
      xaxis: {
        categories: this.yearsData,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              colors: '#008FFB',
            },
          },
          title: {
            text: "Capital remboursé en milliers d'euros",
            style: {
              color: '#008FFB',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: 'Capital remboursé',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#00E396',
          },
          labels: {
            style: {
              colors: '#00E396',
            },
          },
          title: {
            text: "Intêrets payés en euros",
            style: {
              color: '#00E396',
            },
          },
        },
        {
          seriesName: 'Restant dû',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FEB019',
          },
          labels: {
            style: {
              colors: '#FEB019',
            },
          },
          title: {
            text: "Restant dû en milliers d'euros",
            style: {
              color: '#FEB019',
            },
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
      },
    };

    var chart = new ApexCharts(document.querySelector('#combo-chart'), options);
    chart.render();
  }
}
