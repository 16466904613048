import * as Leaflet from 'leaflet';
import {
  Company,
  PaginatedCompaniesSearchResult,
} from '../../shared/model/Companies';

const defaultCoordFrance = {
  latCenter: 46.603354,
  lonCenter: 1.888334,
};
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = Leaflet.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
Leaflet.Marker.prototype.options.icon = iconDefault;

export class MapUtils {
  map!: Leaflet.Map;
  private currentPopup: Leaflet.Popup | null = null;

  constructor() {}

  initMap(companies: PaginatedCompaniesSearchResult): void {
    //si map deja chargée on l'écrase et on en crée une nouvelle
    if (this.map) this.map.remove();
    let currentCenter = defaultCoordFrance;

    if (companies && companies.totalCount > 0)
      currentCenter = this.findCenter(companies);

    this.createMap(currentCenter, companies);
  }

  //find the closest point of all the companies with greater convergence for premium enterprises
  findCenter(companies: PaginatedCompaniesSearchResult): any {
    const center = {
      latCenter: 0,
      lonCenter: 0,
      totalWeight: 0,
    };

    companies.premium.forEach((company) => {
      this.addCompanyToCenter(center, company, 2);
    });

    companies.other.forEach((company) => {
      this.addCompanyToCenter(center, company, 1);
    });

    center.latCenter /= center.totalWeight;
    center.lonCenter /= center.totalWeight;
    return center;
  }

  createMap(center: any, companies: PaginatedCompaniesSearchResult): void {
    this.map = Leaflet.map('map', {
      center: [center.latCenter, center.lonCenter],
    });

    const tiles = Leaflet.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    tiles.addTo(this.map);

    if (companies && companies.totalCount > 0)
      this.createZoomAndMarkers(companies);
  }

  //add the coordinates of each company to the center object
  addCompanyToCenter(center: any, company: Company, weight: number) {
    center.latCenter += company.latitude * weight;
    center.lonCenter += company.longitude * weight;
    center.totalWeight += weight;
  }
  //configurate default zoom and markers positions
  createZoomAndMarkers(companies: PaginatedCompaniesSearchResult) {
    const markersBounds = this.getMarkersBounds(companies);
    this.addMarkersToMap(companies);
    this.map.fitBounds(markersBounds);
  }

  addMarkersToMap(companies: PaginatedCompaniesSearchResult): void {
    if (companies && companies.totalCount > 0) {
      companies.premium.forEach((company) => {
        const marker = Leaflet.marker([
          company.latitude,
          company.longitude,
        ]).bindTooltip(company.commercialName);
        marker.on('click', () => this.navigateToCompanyCard(company));
        marker.addTo(this.map);
      });

      companies.other.forEach((company) => {
        const marker = Leaflet.marker([
          company.latitude,
          company.longitude,
        ]).bindTooltip(company.commercialName);
        marker.on('click', () => this.navigateToCompanyCard(company));
        marker.addTo(this.map);
      });
    }
  }

  //automatically manages the zoom in order to display all the markers on the map
  getMarkersBounds(companies: PaginatedCompaniesSearchResult): Leaflet.LatLngBounds {
    const markers: Leaflet.LatLng[] = [];

    companies.premium.forEach((company) => {
      markers.push(Leaflet.latLng(company.latitude, company.longitude));
    });

    companies.other.forEach((company) => {
      markers.push(Leaflet.latLng(company.latitude, company.longitude));
    });

    return Leaflet.latLngBounds(markers);
  }

  //Pop-up scroll mouseHover

  showMarkerPopup(company: Company) {
    if (this.currentPopup) {
      this.currentPopup.remove();
    }

    this.currentPopup = Leaflet.popup({ closeButton: false }).setLatLng([
      company.latitude,
      company.longitude,
    ]).setContent(`
    <div style="font-size: 12px; max-width: 100px; max-height: 20px; text-align: center; display: flex; align-items: center; justify-content: center;">
      <p>${company.commercialName}</p>
    </div>
      `);

    this.currentPopup.addTo(this.map);
  }

  hideMarkerPopup() {
    if (this.currentPopup) {
      this.currentPopup.remove();
      this.currentPopup = null;
    }
  }

  navigateToCompanyCard(company: Company): void {
    const companyId = company._id;
    const element = document.getElementById(companyId);

    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: "nearest", inline: "center"});
    }
  }

  removeAllMarkers(): void {
    this.map.eachLayer((layer) => {
      if (layer instanceof Leaflet.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }
}
