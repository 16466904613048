<section id="blog">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>Blog</h2>
      <p>Gardez un oeil sur l'actualité</p>
    </div>
    <div *ngIf="blog">
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide center-slide"
            *ngFor="let article of blog; let i = index"
          >
            <Abstract [articleResult]="article"></Abstract>
          </div>

          <div
            class="swiper-slide center-slide"
            *ngFor="let article of blog; let i = index"
          >
            <Abstract [articleResult]="article"></Abstract>
          </div>

          <div class="swiper-slide center-slide">
            <div class="article-container">
              <div class="row abstract-img p-0">
                <div class="col-12">
                  <img
                    class="img-fluid"
                    src="../../assets/img/blog.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="row abstract-text">
                <div class="col-lg-10 col-sm-12">
                  <div class="card">
                    <div class="container">
                      <div class="category row">
                        <div class="col-4 ps-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="40"
                            viewBox="0 0 4 40"
                            fill="none"
                          >
                            <path
                              d="M2 38L2 2"
                              stroke="#FFC451"
                              stroke-width="4"
                              stroke-linecap="round"
                            />
                          </svg>
                          <span class="ps-2">Recherche</span>
                        </div>
                        <div class="col-8 text-muted d-flex align-items-center">
                          Crédit:
                          <a
                            class="link-info"
                            href="https://www.freepik.com/vectors/writing-illustration"
                            >Storyset - Freepik</a
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h4 class="article-title">
                            Vous cherchez un autre article ?
                          </h4>
                        </div>
                        <div class="col-12">
                          <a
                            routerLink="/articles/recherche"
                            class="btn btn-primary mb-3"
                          >
                            Rechercher un article
                          </a>
                        </div>
                      </div>
                      <div class="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <!-- <div class="swiper-pagination"></div> -->
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </div>
</section>
