import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PopulatedArticleResult } from '../../shared/model/Articles';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { BlogService } from '../services/blog.service';
import { DatesService } from '../../shared/services/dates.service';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit {
  id: string = '';
  article!: PopulatedArticleResult;
  date: string = '';
  suggestions: PopulatedArticleResult[] = [];

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService,
    private toastsService: ToasterService,
    private analyticsService: AnalyticsService,
    private title: Title,
    private datesService: DatesService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.blogService.fetchArticle(this.id).subscribe({
      next: (result: PopulatedArticleResult) => {
        this.article = result;
        this.title.setTitle(this.article.article.title);
        this.date = this.datesService.formatDateAlt(
          new Date(this.article.article.dateOfPublishing),
        );
        console.log(this.article.article)
        this.fetchSuggestions();
      },
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });

    this.analyticsService.saveArticleView(this.id).subscribe({
      error: (err: any) => {
        this.toastsService.showError(err.message);
      },
    });
  }

  fetchSuggestions() {
    this.blogService
      .fetchSuggestions(this.article.article._id)
      .subscribe({
        next: (result: PopulatedArticleResult[]) => {
          this.suggestions = result;
        },
        error: (err: any) => {
          this.toastsService.showError(err.message);
        },
      });
  }
}
